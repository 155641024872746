import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Card,
    Form,
    Input,
    Switch,
    DatePicker,
    Checkbox,
    Row,
    Col,
    Select,
    Button,
    InputNumber,
    AutoComplete,
    Spin,
    Modal,
    message,
    Typography,
} from "antd";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import axios from "axios";
import {
    CONTACT_NAME_LIST,
    JOB_CREATE_BY_USER,
    SUB_CATEGORY_LIST,
    US_CITY_LIST,
    US_STATE_LIST,
    JOB_DETAIL,
    COMPANY_NAMES,
    CANDIDATE_CATEGORY_LIST,
    CATEGORY_LIST,
    JOB_ASSIGNMENT_DETAILS,
    GET_BOOLEN_STRING,
    REGISTER_COMPANY_URL,
    REGISTER_COMPANY_URL_USER,
} from "../../../../ApiUrl";
import { NavLink } from "react-router-dom";
import {
    optionEmploymentType,
    optionsexperienceLevel,
    optionsInterviewType,
    optionsSecurity_clearance,
    optionsVisaType,
    yearsOfExpirance,
} from "../../constant/constant";
import {
    PostContactActivity,
    CONTACT_ACTIVITY_MODULE,
} from "../../company/ActivityLogApiCall";
import { showError } from "../../utils/helpers";
import { requestHeader } from "../../../../others/header";
import AssignJob from "../../../modules/Openings/assignJob";
import QuickContactCreate from "./QuickContact";
import BooleanString from "../../../modules/Openings/BooleanString";

const { Option } = Select;
const FormItem = Form.Item;
const formatLocation = (contact) => {
    const { city, state, country } = contact;
    return [city, state, country].filter(Boolean).join(", ");
  };
export default function CreateOpening(props) {
    const formRef = useRef();
    const [form] = Form.useForm();
    const [formCompany] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [contactNameList, setContactNameList] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [subCategoryList, setSubCategoryList] = React.useState([]);
    const [descEditor, setDescEditor] = React.useState([]);
    const [msgSuccess, setMsgSuccess] = useState("");
    const [msgError, setMsgError] = useState("");
    const [jobDesc, setJobDesc] = useState("");
    const [openingId, setOpeningId] = useState("");
    const [stateList, setStateList] = useState([]);
    const [clientNames, setClientNames] = useState([]);
    const [selectedClient, setSelectedClient] = useState();
    const [cityList, setCityList] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [isDurationEnable, setDurationEnable] = useState(false);
    const [isCityEnable, setCityEnable] = useState(false);
    const [creatingState, setCreatingState] = useState(true);
    const [getBooleanString, setGetBooleanString] = useState(false);
    const [selectedRecruiter, setSelectedRecruiter] = useState([]);
    const [selectedFreelancer, setSelectedFreelancer] = useState([]);
    const [selectedBDM, setSelectedBDM] = useState([]);
    const [successValue, setSuccessValue] = useState({
        status: false,
        message: null,
    });
    const [errorValue, setErrorValue] = useState({
        status: false,
        message: null,
    });
    const [globalLoading, setGlobalLoading] = useState({
        generateBoolean: false,
    });
    const [zipCodeError, setZipCodeError] = useState('')
    const [isContactModalVisible, setIsContactModalVisible] = useState(false);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    let history = useHistory();
    let location = useLocation();
    const users = useSelector(({ users }) => users);
    const common = useSelector(({ common }) => common);
    const categoryList = common.category;
    const [booleanData, setGetBooleanData] = useState(null);
    let editor;

    useEffect(() => {
        setUserValues();
    }, [users]);

    useEffect(() => {
        getClientsByRole();
    }, []);

    useEffect(() => {
        getStatesList();
        getCategoriesList();

        if (location.state) {
            setCreatingState(false);
            getOpeningDetail();
            // console.log(location.state.record.opening_id);
            getJobAssignmentDetails(location?.state?.record?.opening_id);
        }
    }, []);
console.log('cityList',cityList)
console.log('stateList',stateList)
    useEffect(() => {
        getCityList(selectedState);
        setCityEnable(selectedState === 3980);
    }, [selectedState]);

    useEffect(() => {
        return () => { };
    }, []);

    const getJobAssignmentDetails = (id) => {
        axios
            .post(
                JOB_ASSIGNMENT_DETAILS,
                {
                    created_by: users.role != "admin" && users.user._id,
                    opening_id: id,
                },
                {
                    headers: { Authorization: users.token },
                }
            )
            .then((res) => {
                // console.log(res.data);
                setSelectedBDM(res.data.data[0].assigned_bdm);
                setSelectedRecruiter(res.data.data[0].assigned_recruiter);
                setSelectedFreelancer(res.data.data[0].assigned_freelancer);
                // console.log(res.data.data[0]);
            })
            .catch((error) => { });
    };

    const getCategoriesList = () => {
        axios
            .get(CATEGORY_LIST, requestHeader())
            .then((res) => {
                setCategories(res.data.data);
            })
            .catch((error) => { });
    };

    const getOpeningDetail = () => {
        axios
            .get(JOB_DETAIL + "/" + location.state.record._id, {
                headers: { Authorization: users.token },
            })
            .then((res) => {
                // console.log(res.data.data[0]);
                setOpeningsValues(res.data.data[0]);

                if (res.data.data[0].boolean_string) {
                    form.setFieldsValue(res.data.data[0].boolean_string);
                    setGetBooleanString(true);
                }
            })
            .catch((error) => { });
    };

    const setOpeningsValues = (values) => {
        setSelectedState(
            values.state.length > 0 ? parseInt(values.state[0].code) : ""
        );
        getContactNameList(values.account_name[0]._id);
        setSelectedClient(values.account_name[0]);
        form.setFieldsValue({
            company_name: values.account_name[0].company_name,
            country: "United States",
            currency: "USD",
            contact_name:
                values.contact_name.length > 0
                    ? values.contact_name.map((user) => user._id)
                    : [],
            opening_title: values.opening_title,
            opening_id: values.opening_id,
            required_skills: values.required_skills,
            required_experience: values.required_experience,
            pay_currency: "",
            pay_type: "",
            stateName: values.state.length > 0 ? parseInt(values.state[0].code) : "",
            city: values.city.length > 0 ? parseInt(values.city[0].code) : "",
            zip_code: values.zip_code,
            number_of_openings: values.number_of_openings,
            max_resumes_allowed: values.max_resumes_allowed,
            local_indicator: values.local_indicator,
            security_clearance: values.security_clearance,
            duration: values.duration,
            category: values.category.length > 0 ? values.category[0].code : "",
            sub_category: values.sub_category,
            employment_type: values.employment_type,
            experience_level: values.experience_level,
            interview_type: values.interview_type,
            visa_type:
                values.visa_type.length > 0
                    ? values.visa_type.map((item, index) => {
                        return item.value;
                    })
                    : [],
            project_start_date: values.project_start_date
                ? moment(values.project_start_date)
                : "",
            project_close_date: moment(values.project_close_date),
            notes: values.notes,
            from: values.salary_range_from,
            to: values.salary_range_to,
            currency: values.currency,
            salary_type: values.salary_type,
            attachments: values.attachments,
            status: values.status,
            short_description: values.short_description,
            boolean_string: booleanData,
        });
        setJobDesc(values.job_description);
        setOpeningId(values.opening_id);
    };
    const setUserValues = () => {
        if (users) {
            form.setFieldsValue({
                // account_name: users.user.company_name,
                country: "United States",
                currency: "USD",
            });
        }
    };

    const getStatesList = () => {
        axios
            .get(US_STATE_LIST, requestHeader())
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((error) => { });
    };

    const getCityList = (stateId) => {
        axios
            .post(US_CITY_LIST, { state_id: stateId }, requestHeader())
            .then((res) => {
                setCityList(res.data.data);
            })
            .catch((error) => { });
    };

    const handleStateChange = (stateCode) => {
        setSelectedState(stateCode);
        form.setFieldsValue({
            stateName: stateCode,
            city: "",
            zip_code: "",
        });
    };



    const getClientsByRole = () => {
        const params = {};
        if (users.role === "bdm") params.bdm_id = users.user._id;
        axios
            .post(COMPANY_NAMES, params, { headers: { Authorization: users.token } })
            .then((res) => {
                setClientNames(res.data.data);
            })
            .catch((error) => { });
    };

    const getContactNameList = useCallback((company_id) => {
        const params = { company_id };

        axios
            .post(CONTACT_NAME_LIST, params, {
                headers: { Authorization: users.token },
            })
            .then((res) => {
                setContactNameList(res.data.data);
            })
            .catch((error) => {
                console.error('Error fetching contacts:', error);
                message.error('Failed to fetch contacts');
            });
    }, [users.token]);

    const getSubCategoriesList = (categoryCode) => {
        const params = { category_code: categoryCode };
        axios
            .post(SUB_CATEGORY_LIST, params, {
                headers: { Authorization: users.token },
            })
            .then((res) => {
                setSubCategoryList(res.data.data);
            })
            .catch((error) => { });
    };

    const handleCategoryChange = (value) => {
        getSubCategoriesList(value);
    };

    const mockVal = (str) => {
        return subCategoryList.filter((item) => {
            return item.name === str;
        });
    };

    const onSearchSubCategories = (searchText) => {
        setSubCategoryList(!searchText ? [] : mockVal(searchText));
    };

    const callActivity = (title, description) => {
        if (users.user.contact_person_details) {
            const contact_person_details = users.user.contact_person_details;
            const contactLogObject = {
                company_id: users.user._id,
                contact_id: users.user.contact_person_details._id,
                module: CONTACT_ACTIVITY_MODULE.OPENING,
                title: title,
                description:
                    description +
                    contact_person_details.first_name +
                    " " +
                    contact_person_details.last_name,
            };
            PostContactActivity(contactLogObject, users.token);
        }
    };
    const setDefaultState = () => {
        setTimeout(() => {
            setSuccess(false);
            setLoading(false);
            setError(false);
            setMsgSuccess("");
            setMsgError("");
        }, 800);
    };

    const createOpening = (newValue) => {
        const title =
            newValue.opening_title + " (#" + newValue.opening_id + ") created";
        const desc = title + " by ";
        try {
            axios
                .post(JOB_CREATE_BY_USER, newValue, {
                    headers: { Authorization: users.token },
                })
                .then((res) => {
                    if (!res.data.error) {
                        callActivity("Add Opening", desc);
                        setSuccess(true);
                        // setLoading(false);
                        setError(false);
                        setMsgSuccess(res.data.message);

                        setDefaultState();
                        setTimeout(() => {
                            history.goBack();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setSuccess(false);
                    setLoading(false);
                    setError(true);
                    let errorMessage = "";
                    {
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            return (errorMessage += value + ", ");
                        });
                    }
                    setMsgError(errorMessage);
                    setDefaultState();
                });
        }
        catch (error) {
            handleApiError(error);
        }
    };

    const updateOpening = (newValue) => {
        const title =
            newValue.opening_title + " (#" + newValue.opening_id + ") details update";

        const desc = title + " by ";
        try {


            axios
                .put(JOB_DETAIL + "/" + location.state.record._id, newValue, {
                    headers: { Authorization: users.token },
                })
                .then((res) => {
                    if (!res.data.error) {
                        callActivity("Opening update", desc);
                        setSuccess(true);
                        setError(false);
                        setMsgSuccess(res.data.message);
                        setDefaultState();
                        setTimeout(() => {
                            history.goBack();
                        }, 3000);
                    }
                    //  console.log('updateOpening completed successfully');
                })
                .catch((error) => {
                    setSuccess(false);
                    // setLoading(false);
                    setError(true);
                    let errorMessage = "";
                    {
                        Object.entries(error.response.data.errors).map(([key, value]) => {
                            return (errorMessage += value + ", ");
                        });
                    }
                    setMsgError(errorMessage);
                    setDefaultState();
                    // console.log(msgError);
                });
        } catch (error) {
            handleApiError(error);
            // setLoading(false);
        }
    };
    const handleApiError = (error) => {
        setError(true);
        let errorMessage = "";
        if (error.response && error.response.data && error.response.data.errors) {
            Object.entries(error.response.data.errors).forEach(([key, value]) => {
                errorMessage += value + ", ";
            });
        } else {
            errorMessage = "An unexpected error occurred.";
        }
        setMsgError(errorMessage);
    };
    useEffect(() => {
        if (success || error) {
            const timer = setTimeout(() => {
                setSuccess(false);
                setError(false);
                setMsgSuccess("");
                setMsgError("");
            }, 200);
            return () => clearTimeout(timer);
        }
    }, [success, error]);
    const onFinish = (values) => {
        // console.log('loading state from onFinish before setLoading true=>', loading)
        if (loading) return;
        setLoading(true)
        // console.log('loading state from onFinish after setLoading true=>', loading)
        try {
            const editorData = descEditor.getData();
            // handleClose();
            const newValue = {
                opening: {
                    account_name: selectedClient._id,
                    contact_name: values.contact_name,
                    opening_title: values.opening_title,
                    opening_id: location.state
                        ? openingId
                        : selectedClient.company_code +
                        Math.floor(100000 + Math.random() * 900000),
                    required_skills: values.required_skills,
                    required_experience: values.required_experience,
                    pay_currency: "",
                    pay_type: "",
                    country: values.country,
                    state: values.stateName,
                    city: values.city,
                    zip_code: values.zip_code,
                    number_of_openings: values.number_of_openings,
                    max_resumes_allowed: values.max_resumes_allowed,
                    local_indicator: values.local_indicator,
                    security_clearance: values.security_clearance,
                    job_description: editorData,
                    duration: isDurationEnable
                        ? ""
                        : values.duration
                            ? values.duration.toString()
                            : "",
                    category: values.category,
                    sub_category: values.sub_category,
                    employment_type: values.employment_type,
                    experience_level: values.experience_level,
                    interview_type: values.interview_type,
                    visa_type: values.visa_type,
                    project_start_date: values.project_start_date,
                    project_close_date: values.project_close_date,
                    notes: values.notes,
                    salary_range:
                        (values.from === undefined ||
                            values.from === "undefined" ||
                            values.from === null ||
                            values.from === "null"
                            ? 0
                            : values.from) +
                        "-" +
                        (values.to === undefined ||
                            values.to === "undefined" ||
                            values.to === null ||
                            values.to === "null"
                            ? 0
                            : values.to),
                    currency: values.currency,
                    salary_type: values.salary_type,
                    attachments: values.attachments,
                    status: values.status,
                    role: users.role,
                    short_description: values.short_description,
                    company_name: selectedClient.company_name,
                    boolean_string: booleanData,
                },
                assingJob: {
                    assigned_by_bdm: users.user._id,
                    recruiter_id: selectedRecruiter,
                    freelance_id: selectedFreelancer,
                    bdm_id: selectedBDM,
                },
            };

            if (location.state) {
                updateOpening(newValue);
            } else {
                createOpening(newValue);
            }
        } catch (error) {
            console.error('Error saving job opening:', error);
            setError(true);
            setMsgError('Failed to save job opening. Please try again.');
        }
    };


    // const handleBooleanString = (status) => {
    //     if (!status) {
    //         setGetBooleanString(false);
    //         return 1;
    //     }
    //     const description = descEditor.getData();
    //     const plainString = stripHtmlTags(description);
    //     const jobTitle = form.current.getFieldValue("opening_title");

    //     if (!jobTitle) {
    //         setGetBooleanString(false);
    //         setErrorValue({
    //             status: true,
    //             message: "Job opening title is required!",
    //         });

    //         setTimeout(() => {
    //             setErrorValue({
    //                 status: false,
    //                 message: null,
    //             });
    //         }, 3000);

    //         return 1;
    //     }

    //     if (!plainString) {
    //         setGetBooleanString(false);
    //         setErrorValue({
    //             status: true,
    //             message: "Job opening details is required!",
    //         });

    //         setTimeout(() => {
    //             setErrorValue({
    //                 status: false,
    //                 message: null,
    //             });
    //         }, 3000);

    //         return 1;
    //     }

    //     setGetBooleanString(true);
    //     setGlobalLoading({ ...globalLoading, generateBoolean: true });

    //     if (location.state) {
    //         callGetBooleanString();
    //     }
    // };

    // const callGetBooleanString = () => {
    //     const description = descEditor.getData();
    //     const plainString = stripHtmlTags(description);
    //     const jobTitle = form.current.getFieldValue("opening_title");

    //     if (!jobTitle) {
    //         setGetBooleanString(false);
    //         setErrorValue({
    //             status: true,
    //             message: "Job opening title is required!",
    //         });

    //         setTimeout(() => {
    //             setErrorValue({
    //                 status: false,
    //                 message: null,
    //             });
    //         }, 3000);

    //         return 1;
    //     }

    //     if (!plainString) {
    //         setGetBooleanString(false);
    //         setErrorValue({
    //             status: true,
    //             message: "Job opening details is required!",
    //         });

    //         setTimeout(() => {
    //             setErrorValue({
    //                 status: false,
    //                 message: null,
    //             });
    //         }, 3000);

    //         return 1;
    //     }
    //     axios
    //         .post(
    //             GET_BOOLEN_STRING,
    //             {
    //                 prompt: `
    //   Job title: ${jobTitle}
    //   job description: ${plainString}
    //   `,
    //             },
    //             requestHeader()
    //         )
    //         .then((response) => {
    //             // console.log(response.data);
    //             form.setFieldsValue(response.data.data);
    //             setSuccessValue({
    //                 status: true,
    //                 message: "Generate successfully",
    //             });

    //             setTimeout(() => {
    //                 setSuccessValue({
    //                     status: false,
    //                     message: null,
    //                 });
    //             }, 3000);
    //         })
    //         .catch((error) => {
    //             setErrorValue({
    //                 status: true,
    //                 message: "Something went wrong",
    //             });
    //             setTimeout(() => {
    //                 setErrorValue({
    //                     status: false,
    //                     message: null,
    //                 });
    //             }, 3000);
    //         })
    //         .finally((f) => {
    //             setGlobalLoading({ ...globalLoading, generateBoolean: false });
    //         });
    // };
    // function stripHtmlTags(input) {
    //     // Use a regular expression to remove HTML tags
    //     return input.replace(/<\/?[^>]+(>|$)/g, "");
    // }

    // Example usage

    // useEffect(() => {
    //     if (getBooleanString && !location.state) {
    //         callGetBooleanString();
    //     }
    // }, [getBooleanString]);
    //zip code validation
    const validateZipCode = (_, value) => {
        if (!value) {
            setZipCodeError('')
            return Promise.resolve()
        }

        const basicZipRegex = /^\d{5}$/
        const extendedZipRegex = /^\d{5}-\d{4}$/

        if (basicZipRegex.test(value) || extendedZipRegex.test(value)) {
            setZipCodeError('')
            return Promise.resolve()
        } else {
            let errorMsg = 'Please enter a valid US zip code. '
            if (value.length !== 5 && value.length !== 10) {
                errorMsg += 'It should be either 5 digits or 5 digits followed by a hyphen and 4 digits.'
            } else if (value.length === 10 && value[5] !== '-') {
                errorMsg += 'For a 9-digit zip code, please include a hyphen after the first 5 digits.'
            } else {
                errorMsg += 'It should only contain numbers and possibly a hyphen.'
            }
            setZipCodeError(errorMsg)
            return Promise.reject(errorMsg)
        }
    }

    //contact creation

    const handleModalOk = useCallback(() => {
        formCompany.validateFields().then(values => {
            createCompany(values);
        });
    }, [formCompany]);

    const handleContactCreated = useCallback((newContact) => {
        setContactNameList(prevContacts => [...prevContacts, newContact]);
        setIsContactModalVisible(false);
    }, []);

    const handleClientNameStateChange = (e) => {
        const selectedObj = clientNames.find((item) => item.company_name == e);
        setSelectedClient(selectedObj);
        setSelectedCompany(selectedObj)
        form.setFieldsValue({ contact_name: undefined });
        if (selectedObj && !selectedObj.isNew) {
            getContactNameList(selectedObj._id);
        } else {
            setContactNameList([]);
        }

    };
    const createCompany = useCallback(async (values) => {
        try {
            const companyData = {
                company_name: values.company_name,
                company_code: values.company_name.replace(/ /g, ""),
                email_1: values.email || '',
                contact_number: values.contact_number || '',
                country: "United States",
                role: "client",
                profile: "client",
                status: "Active",
                category: "client",
                assigned_to_bdm: users.user._id,
                agreement_status: "Hold",
                password: Math.random().toString(36).slice(-8),
                confirm_password: Math.random().toString(36).slice(-8),
                acceptTerms: true,
            };

            const url = users.role === "company" ? REGISTER_COMPANY_URL : REGISTER_COMPANY_URL_USER;
            const config = users.role === "company" ? {} : { headers: { Authorization: users.token } };

            const response = await axios.post(url, companyData, config);
            const newCompany = { ...response.data.data, isNew: true };

            // setCompanies(prevCompanies => [...prevCompanies, newCompany]);
            setSelectedCompany(newCompany);
            form.setFieldsValue({ company_name: newCompany._id, contact_name: undefined });
            setIsModalVisible(false);
            setContactNameList([]);
            getClientsByRole();
            message.success('Company created successfully. You can now add contacts to this company.');
        } catch (error) {
            console.error('Error creating company:', error);
            message.error('Failed to create company');
        }
    }, [users.role, users.user._id, users.token, form]);

    const handleSearch = useCallback((value) => {
        setSearchValue(value);
    }, []);

    const showModal = useCallback(() => {
        formCompany.setFieldsValue({ company_name: searchValue });
        setIsModalVisible(true);
    }, [formCompany, searchValue]);
    return (
        <div>
            <Form form={form}
                // ref={formRef}
                onFinish={onFinish} layout="vertical">
                {showError(success, msgSuccess, error, msgError)}

                <Card
                    title="Company Information"
                    className="mb-6"
                    extra={
                        <NavLink
                            to={
                                {
                                    // pathname: "/company/job-openings",
                                }
                            }
                        >
                            <Button onClick={() => props.history.goBack()} type="Secondary">
                                Back
                            </Button>
                        </NavLink>
                    }
                >
                    <Row gutter={24}>
                        <Col span={8}>
                            <FormItem
                                label="Company Name"
                                name={"company_name"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Company Name.",
                                    },
                                ]}
                            >
                                {/* <Input dplaceholder="Company Name" /> */}
                                <Select
                                    showSearch
                                    onChange={handleClientNameStateChange}
                                    disabled={!creatingState}
                                    loading={loading}
                                    onSearch={handleSearch}
                                    optionFilterProp="children"
                                    placeholder="Select a company"
                                    notFoundContent={
                                        <Button type="link" onClick={showModal}>
                                            Add "{searchValue}" as new company
                                        </Button>
                                    }
                                >
                                    {clientNames.map((item, index) => {
                                        return (
                                            <option value={item.company_name} key={index.toString()}>
                                                {item.company_name}
                                            </option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                label="Contact Name"
                                name={"contact_name"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Contact Name.",
                                    },
                                ]}
                            >
                                <Select
                                    filterOption={(input, option) =>
                                        option ? option.children.toLowerCase().includes(input) : ""
                                    }
                                    mode={"multiple"}
                                    allowClear
                                    placeholder="Select contacts"
                                    disabled={!selectedCompany}
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Button type="link" onClick={() => setIsContactModalVisible(true)}>
                                                Add New Contact
                                            </Button>
                                        </>
                                    )}
                                >
                                    {contactNameList.map((user, index) => {
                                        return (
                                            <Option value={user._id} key={index.toString()}>
                                                {user.display_name.charAt(0).toUpperCase() +
                                                    user.display_name.slice(1)}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </Card>

                <Card title="Opening Information" className="mb-6">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem
                                label="Opening Title"
                                name={"opening_title"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Opening Title.",
                                    },
                                ]}
                            >
                                <Input placeholder="Opening Title" />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Required Skills"
                                name={"required_skills"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Required Skills.",
                                    },
                                ]}
                            >
                                <Input placeholder="Required Skills" />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Experience Level"
                                name={"experience_level"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Experience Level.",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select an experience level"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children
                                            .toLowerCase()
                                            .localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {optionsexperienceLevel.map((item, index) => (
                                        <Option value={item.value} key={index.toString()}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Required Experience"
                                name={"required_experience"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Required Experience.",
                                    },
                                ]}
                            >
                                <Select>
                                    {yearsOfExpirance.map((option, index) => (
                                        <option key={index.toString()} value={option.value}>
                                            {option.label +
                                                (option.label === "0"
                                                    ? ""
                                                    : option.label === "1"
                                                        ? " year"
                                                        : " years")}
                                        </option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={20}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <FormItem label="Salary Range" name={"from"}>
                                                <InputNumber
                                                    defaultValue={0}
                                                    className={"w-100"}
                                                    min={0}
                                                    placeholder="from"
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label=" " name={"to"}>
                                                <InputNumber
                                                    defaultValue={0}
                                                    className={"w-100"}
                                                    min={0}
                                                    placeholder="to"
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <FormItem label="Currency" name={"currency"}>
                                        <Select>
                                            <Option value="USD" selected>
                                                $(USD)
                                            </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem
                                        label="Pay Type"
                                        name={"salary_type"}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Pay Type",
                                            },
                                        ]}
                                    >
                                        <Select>
                                            <Option value="Hourly" selected>
                                                Hourly
                                            </Option>
                                            <Option value="Daily"> Daily </Option>
                                            <Option value="Weekly"> Weekly </Option>
                                            <Option value="Monthly"> Monthly </Option>
                                            <Option value="Yearly"> Yearly </Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={4}>
                            <FormItem label="Country" name={"country"}>
                                <Input disabled={true} />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="State" name={"stateName"}>
                                <Select
                                    onChange={handleStateChange}
                                    showSearch
                                    style={{ width: 250 }}
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={
                                        (optionA, optionB) => optionA.children.toLowerCase()
                                        // .localeCompare(optionB.children.toLowerCase())
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select State.",
                                        },
                                    ]}
                                >
                                    {stateList.map((state, index) => {
                                        return (
                                            <Option value={state.code} key={index.toString()}>
                                                {state.state}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="City" name={"city"}>
                                <Select
                                    disabled={isCityEnable}
                                    showSearch
                                    style={{ width: 250 }}
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children
                                            .toLowerCase()
                                            .localeCompare(optionB.children.toLowerCase())
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select City.",
                                        },
                                    ]}
                                >
                                    {cityList.map((city, index) => {
                                        return (
                                            <Option value={city.code} key={index.toString()}>
                                                {city.city}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="Zip Code" name={"zip_code"} rules={[
                                { required: true, message: 'Please enter a zip code' },
                                { validator: validateZipCode }
                            ]}
                                help={zipCodeError ? <Typography.Text type="danger">{zipCodeError}</Typography.Text> : 'Enter a 5-digit zip code or a 9-digit zip code with a hyphen'}
                            >
                                <Input disabled={isCityEnable} placeholder="e.g., 12345 or 12345-6789" />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem
                                label="Number of Openings"
                                name={"number_of_openings"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Number of Openings.",
                                    },
                                ]}
                            >
                                <InputNumber
                                    className={"w-100"}
                                    placeholder="Number of Openings"
                                    min={1}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem
                                label="Max Resumes Allowed"
                                name={"max_resumes_allowed"}
                            >
                                <InputNumber
                                    className={"w-100"}
                                    placeholder="Max Resumes Allowed"
                                    min={1}
                                />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="Local Indicator" name={"local_indicator"}>
                                <Checkbox.Group style={{ width: "100%" }}>
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value="Local">Local</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="Nonlocal">Nonlocal</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label="Security Clearance" name={"security_clearance"}>
                                <Select>
                                    {optionsSecurity_clearance.map((option, index) => (
                                        <option key={index.toString()} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={24}>
                            <FormItem label="Short Description" name={"short_description"}>
                                <Input placeholder=" " />
                            </FormItem>
                        </Col>

                        <Col span={24}>
                            <FormItem
                                label="Job Description"
                                name={"job_description"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Job Description.",
                                    },
                                ]}
                            >
                                <CKEditor
                                    onChange={(event, editor) => {
                                        //job_description = editor.getData();
                                    }}
                                    editor={ClassicEditor}
                                    data={jobDesc}
                                    onReady={(newEditor) => {
                                        editor = newEditor;
                                        setDescEditor(newEditor);
                                        // You can store the "editor" and use when it is needed.
                                    }}
                                />
                            </FormItem>
                        </Col>

                        <Col span={24}>
                            {/* <div style={{ marginBottom: "10px" }}>
                                <span>{"Generate Boolean String: "}</span>
                                <Switch
                                    checked={getBooleanString}
                                    onChange={handleBooleanString}
                                    style={{ marginLeft: "10px" }}
                                />
                            </div>
                            {globalLoading.generateBoolean && <Spin />}
                            {showError(
                                successValue.status,
                                successValue.message,
                                errorValue.status,
                                errorValue.message
                            )}
                            {getBooleanString &&
                                [
                                    "boolean_string_1",
                                    "boolean_string_2",
                                    "boolean_string_3",
                                ]?.map((string, idx) => (
                                    <FormItem
                                        key={string}
                                        label={`Boolean String ${idx + 1}`}
                                        name={string}
                                    >
                                        <Input placeholder="Boolean String" />
                                    </FormItem>
                                ))} */}

                                <BooleanString data={""} description={
                                
                                    form.getFieldValue("job_description")
                                } title= {form.getFieldValue("opening_title")}
                                location={
                                     form.getFieldValue("city") + ", " + form.getFieldValue("stateName") + ", " + form.getFieldValue("country")

                                }
                                required_skills={form.getFieldValue("required_skills")}
                                setGetBooleanData={setGetBooleanData}
                                
                                
                                
                                />
                        </Col>
                    </Row>
                </Card>

                <Card title="Duration & Type" className="mb-6">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label="Duration (Month)" name={"duration"}>
                                <Input
                                    disabled={isDurationEnable}
                                    // className={"w-100"}
                                    placeholder="Duration"
                                    min={1}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Category"
                                name={"category"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Category.",
                                    },
                                ]}
                            >
                                <Select
                                    onChange={handleCategoryChange}
                                    style={{ width: "100%" }}
                                >
                                    {/* {categoryList !== undefined &&
                    categoryList.map((category, index) => (
                      <option key={index.toString()} value={category.code}>
                        {category.name}
                      </option>
                    ))} */}

                                    {categories.map((category, index) => (
                                        <option key={index.toString()} value={category.code}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label="SubCategory" name={"sub_category"}>
                                {/* <Select>
                                    {subCategoryList !== undefined &&
                                    subCategoryList.map(({category, index}) => (
                                        <option value={category.name}  key={index} >{category.name}</option>
                                    ))}
                                </Select> */}
                                <AutoComplete
                                    options={subCategoryList}
                                    style={{
                                        width: 200,
                                    }}
                                    onSearch={onSearchSubCategories}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Employment Type"
                                name={"employment_type"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Employment Type.",
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value) => {
                                        const type = value === "D_FT" ? true : false;
                                        setDurationEnable(type);
                                    }}
                                >
                                    {optionEmploymentType.map((item, index) => (
                                        <Option value={item.value} key={index.toString()}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Status"
                                name={"status"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Status.",
                                    },
                                ]}
                            >
                                <Select>
                                    <Option value="Active"> Active </Option>
                                </Select>
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem
                                label="Interview Type"
                                name={"interview_type"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Interview Type.",
                                    },
                                ]}
                            >
                                <Select>
                                    {optionsInterviewType.map((item, index) => (
                                        <Option key={index.toString()} value={item.value}>
                                            {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem
                                label="Visa Type"
                                name={"visa_type"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter opening's Visa Type.",
                                    },
                                ]}
                            >
                                <Select mode="multiple">
                                    {optionsVisaType.map((visaType, index) => (
                                        <Option key={index.toString()} value={visaType.value}>
                                            {visaType.label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="project_start_date"
                                label="Project Start Date"
                                rules={[{ required: true, message: 'Please select project start date' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={(current) => {

                                        return current && current < moment().startOf('day');
                                    }}
                                    onChange={() => form.setFieldsValue({ project_close_date: null })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="project_close_date"
                                label="Project End Date"
                                rules={[{ required: true, message: 'Please select project end date' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={(current) => {
                                        const startDate = form.getFieldValue('project_start_date');

                                        return startDate && current && current < moment(startDate).startOf('day');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <FormItem label="Notes" name={"notes"}>
                                <Input placeholder="Notes" />
                            </FormItem>
                        </Col>
                    </Row>
                </Card>

                <AssignJob
                    isNew
                    recruiters={selectedRecruiter}
                    bdms={selectedBDM}
                    freelancers={selectedFreelancer}
                    getSelectedRecruiter={(value) => {
                        setSelectedRecruiter(value);
                    }}
                    getSelectedFreelancer={(value) => {
                        setSelectedFreelancer(value);
                    }}
                    getSelectedBDM={(value) => {
                        setSelectedBDM(value);
                    }}
                />

                <Card className="mb-6">
                    <Row>
                        <Button
                            type="primary"
                            className="d-flex align-items-center"
                            htmlType="submit"
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save'}
                            {loading && <span className="mx-3 spinner spinner-white"></span>}
                        </Button>
                    </Row>
                </Card>
            </Form>
            <Modal
                title="Add New Contact"
                open={isContactModalVisible}
                onCancel={() => setIsContactModalVisible(false)}
                footer={null}
            >
                <QuickContactCreate
                    companyId={selectedCompany?._id}
                    token={users.token}
                    onContactCreated={handleContactCreated}
                />
            </Modal>

            <Modal
                title="Add New Company"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form form={formCompany} layout="vertical">
                    <Form.Item
                        name="company_name"
                        label="Company Name"
                        rules={[{ required: true, message: 'Please input the company name!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
