import React, { useEffect, useState } from "react";
import {
  Form,
  Col,
  Row,
  Card,
  Select,
  Button,
  Typography,
  Input,
  message,
  Spin,
  Modal,
  Space,
} from "antd";
import axios from "axios";
import {
  JOB_DETAIL,
  ASSIGN_JOB,
  JOB_BDM_LIST,
  JOB_ASSIGNMENT_DETAILS,
  JOB_RECRUITER_LIST,
  JOB_UPDATE,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ActivityLogUI from "../ActivityLog";
import { showError } from "../../pages/utils/helpers";
import OpeningDetailUi from "./OpeningDetailUI";
import { logoutHandler } from "../../../others/logout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { requestHeader } from "../../../others/header";
import BooleanString from "./BooleanString";
import {
  FacebookOutlined,
  LinkedinOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  optionEmploymentType,
  optionsInterviewType,
} from "../../pages/constant/constant";
import { RoomOutlined } from "@material-ui/icons";

const { Option } = Select;
const { Text } = Typography;
const FormItem = Form.Item;
const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL


export default function OpeningsDetail(props) {
  const [openingDetails, setOpeningDetails] = useState("");
  const [allBDM, setAllBDM] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [selectedBDM, setSelectedBDM] = useState([]);
  const [recruiterList, setAllRecruiterList] = useState([]);
  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [booleanData, setBooleanData] = useState([]);

  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false);
const [iframeCode, setIframeCode] = useState("");


  const users = useSelector(({ users }) => users);
  let location = useLocation();
  const history = useHistory();
  const { openingId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Function to show the Embed Modal
const showEmbedModal = () => {
  const code = generateIframe();
  setIframeCode(code); // Store the iframe code
  setIsEmbedModalOpen(true); // Open the modal
};

// Function to hide the Embed Modal
const handleEmbedModalCancel = () => {
  setIsEmbedModalOpen(false);
};

// Function to copy the iframe code to clipboard
const copyEmbedCode = () => {
  navigator.clipboard
    .writeText(iframeCode)
    .then(() => {
      message.success("Embed code copied to clipboard!");
    })
    .catch(() => {
      message.error("Failed to copy embed code.");
    });
};

  const copyToClipboard = () => {
    const iframeCode = generateIframe();
    navigator.clipboard
      .writeText(iframeCode)
      .then(() => {
        message.success("Embed code copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy embed code.");
      });
  };

  // Create the iframe embed code
  const generateIframe = () => {
    return `<iframe src="${DOMAIN_URL}/openings/${openingDetails?.opening_id}"
    width="600" height="400" frameborder="0" allowfullscreen>
    </iframe>`;
  };

  const handleShare = (platform) => {
    const jobTitle = "Job Title Here"; // Replace with job title from data
    const jobDescription =
      "Job Description Here Hello Description of Job is here."; // Replace with job description from data
    const jobUrl = `${DOMAIN_URL}/openings/${openingId}`; // Construct job URL dynamically

    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      jobUrl
    )}&title=${encodeURIComponent(jobTitle)}&summary=${encodeURIComponent(
      jobDescription
    )}&source=pk.indeed.com`;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      jobUrl
    )}`;
    if (platform === "facebook") {
      window.open(facebookShareUrl, "_blank");
    } else if (platform === "linkedin") {
      window.open(linkedInShareUrl, "_blank");
    }
  };

  useEffect(() => {
    getAllBDMList();
    getOpeningDetail();
    getAllRecruterList();
    return () => {};
  }, []);

  const getAllRecruterList = () => {
    axios
      .get(JOB_RECRUITER_LIST, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setAllRecruiterList(res.data.data);
        if (error?.response?.status == 401) {
          logoutHandler(history);
        }
      })
      .catch((error) => {});
  };

  const getOpeningDetail = () => {
    axios
      .get(JOB_DETAIL + "/" + location.state.record._id, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setOpeningDetails(res.data.data[0]);
        setBooleanData(res.data.data[0].boolean_string);

        getJobAssignmentDetails(res.data.data[0].opening_id);
        let bdm =
          res.data.data[0].assigned_bdm ||
          res.data.data[0].account_name[0].assigned_to_bdm;

        setSelectedBDM(bdm.map((e) => e._id || e));
      })
      .catch((error) => {});
  };

  const getJobAssignmentDetails = (id) => {
    axios
      .post(
        JOB_ASSIGNMENT_DETAILS,
        {
          created_by: users.role != "admin" && users.user._id,
          opening_id: id,
        },
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => {
        console.log(res.data);
        setSelectedBDM(res.data.data[0].assigned_bdm);
        setSelectedRecruiter(res.data.data[0].assigned_recruiter);
      })
      .catch((error) => {});
  };

  const getAllBDMList = () => {
    axios
      .get(JOB_BDM_LIST, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setAllBDM(res.data.data);
      })
      .catch((error) => {});
  };

  const handleAssignChange = (value) => {
    setSelectedBDM(value);
  };

  const postAssignJob = () => {
    const param = {
      opening_id: openingDetails.opening_id,
      bdm_id: selectedBDM,
      recruiter_id: selectedRecruiter,
      company_id: openingDetails.account_name[0]?._id,
      freelance_id: "",
      created_by: users.user?._id,
    };
    setLoading(true);
    axios
      .put(ASSIGN_JOB, param, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setSelectedBDM([]);
        setMsgSuccess(res.data.message);
        getOpeningDetail();
        setDefaultState();
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        let errorMessage = "";
        {
          error.response.data.keys.length > 0 &&
            Object.entries(error.response.data.errors).map(([key, value]) => {
              return (errorMessage += value + ", ");
            });
        }
        setmsgError(errorMessage);
        setDefaultState();
      });
  };

  // const getJobAssignmentDetails = (id) => {
  //   axios
  //     .post(
  //       JOB_ASSIGNMENT_DETAILS,
  //       {
  //         created_by: users.user._id,
  //         // notforbdm: 1,
  //         opening_id: id,
  //       },
  //       {
  //         headers: { Authorization: users.token },
  //       }
  //     )
  //     .then((res) => {
  //       const data = res.data.data;
  //       if (data.length > 0) {
  //         let assigned_rec_arr = [];
  //         let assigned_free_arr = [];
  //         data.forEach((element) => {
  //           assigned_rec_arr.push(...element.assigned_recruiter);
  //           assigned_free_arr.push(...element.assigned_freelancer);
  //         });
  //         setSelectedRecruter(assigned_rec_arr);
  //         setSelectedFreelancer(assigned_free_arr);
  //       }

  //       // getJobAssignmentDetailsWithoutBdm(id);
  //     })
  //     .catch((error) => {});
  // };

  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setLoading(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };

  // console.log("openingDetails", openingDetails.);
  return (
    <div>
      <Row gutter={24}>
        <Col span={14}>
          <OpeningDetailUi
            record={location.state.record}
            openingDetails={openingDetails}
            goBack={props.history.goBack}
          />
          <br />
          <ActivityLogUI param={openingDetails} isActivityLogs={false} />
        </Col>
        <Col span={10}>
          <ActivityLogUI param={openingDetails} isActivityLogs={true} />
          <br />
          <Card title="Assign Job" className="px-0 py-0">
            <Row gutter={24}>
              <Col span={20}>
                {/*{openingDetails && openingDetails.assigned_bdm.length <= 0 ? (*/}
                <Text>Assigned Job to BDM:</Text>
                <FormItem>
                  <Row className="d-flex justify-content-between">
                    <Col span={15}>
                      <Select
                        mode="multiple"
                        value={selectedBDM}
                        onChange={handleAssignChange}
                        placeholder="Select"
                      >
                        {allBDM !== undefined &&
                          allBDM.map((user, index) => {
                            return (
                              <Option value={user._id} key={index.toString()}>
                                {user.display_name.charAt(0).toUpperCase() +
                                  user.display_name.slice(1)}
                              </Option>
                            );
                          })}
                      </Select>
                    </Col>
                    <Button
                      className="d-flex align-items-center"
                      type="primary"
                      onClick={postAssignJob}
                    >
                      Save
                      {loading && (
                        <span className="mx-3 spinner spinner-white"> </span>
                      )}
                    </Button>
                  </Row>
                </FormItem>

                <br />

                <Text>Assigned Job to Recruiter :</Text>
                <FormItem className="mt-2">
                  <Row className="d-flex justify-content-between">
                    <Col span={15}>
                      <Select
                        mode="multiple"
                        value={selectedRecruiter}
                        onChange={(e) => {
                          setSelectedRecruiter(e);
                        }}
                        placeholder="Select"
                      >
                        {recruiterList.length > 0
                          ? recruiterList.map((user, index) => {
                              return (
                                <Option value={user._id} key={index.toString()}>
                                  {user.display_name
                                    ? user.display_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      user.display_name.slice(1)
                                    : ""}
                                </Option>
                              );
                            })
                          : null}
                      </Select>
                    </Col>

                    {null ? null : (
                      <Button
                        className="d-flex align-items-center"
                        type="primary"
                        onClick={() => postAssignJob("Recruiter")}
                      >
                        Save
                        {loading && (
                          <span className="mx-3 spinner spinner-white"> </span>
                        )}
                      </Button>
                    )}
                  </Row>
                </FormItem>

                {/*) : (*/}
                <>
                  {/* <Text type="secondary">{"Job Assigned to "}</Text>
                  <Text type="primary">
                    {openingDetails && openingDetails.assigned_bdm.length > 0
                      ? openingDetails.assigned_bdm.map(e => {
                        return e.display_name + ', '
                      })
                      : ""}
                  </Text> */}
                </>
                {/*)}*/}
                {showError(success, msgSuccess, error, msgError)}
              </Col>
            </Row>
          </Card>

          <br />

          <BooleanString
            jobId={openingDetails._id}
            description={openingDetails?.job_description}
            category={openingDetails.category?  openingDetails?.category[0]?.name : ""}
            title={openingDetails?.opening_title}
            city={openingDetails?.city?.city}
            state={openingDetails?.state?.state}
            country={openingDetails?.country}
            required_skills={openingDetails?.required_skills}
          />
          {/* <Button onClick={() => window.open(linkedInShareUrl, "_blank")}>
            Share on LinkedIn
          </Button> */}

          <>
            <Button
              type="primary"
              icon={<ShareAltOutlined />}
              onClick={showModal}
            >
              Share
            </Button>
            <Button
      type="default"
      icon={<RoomOutlined />}
      onClick={showEmbedModal}
    >
      Embed Code
    </Button>

    {/* Embed Code Modal */}
    <Modal
      title="Embed Job Posting on Your Website"
      open={isEmbedModalOpen}
      onCancel={handleEmbedModalCancel}
      footer={null}
    >
      <Typography.Title level={5}>How to Embed</Typography.Title>
      <Typography.Paragraph>
        Copy the code below and paste it into your website's HTML where you want the job posting to appear.
      </Typography.Paragraph>
      <Card bordered style={{ background: "#f5f5f5" }}>
        <pre
          style={{
            background: "#333",
            color: "#fff",
            padding: "15px",
            borderRadius: "5px",
            fontSize: "14px",
            overflowX: "auto",
          }}
        >
          {iframeCode}
        </pre>
      </Card>
      <div style={{ textAlign: "right", marginTop: "15px" }}>
        <Button type="primary" onClick={copyEmbedCode}>
          Copy to Clipboard
        </Button>
      </div>
    </Modal>

            <Modal
              title="Share this post"
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
            >
              <Space size="large">
                <Button
                  icon={<FacebookOutlined />}
                  type="primary"
                  onClick={() => handleShare("facebook")}
                  style={{ backgroundColor: "#3b5998", borderColor: "#3b5998" }}
                >
                  Facebook
                </Button>
                <Button
                  icon={<LinkedinOutlined />}
                  type="primary"
                  onClick={() => handleShare("linkedin")}
                  style={{ backgroundColor: "#0e76a8", borderColor: "#0e76a8" }}
                >
                  LinkedIn
                </Button>
              </Space>
            </Modal>
          </>
        </Col>
      </Row>
    </div>
  );
}
