import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import {
  CANDIDATE,
  CANDIDATE_NOTE_DELETE,
  CANDIDATE_NOTE_UPDATE,
  CANDIDATE_NOTES,
  CANDIDATE_UPDATE_LABEL,
  CANDIDATE_UPDATE_PARTIALLY,
  CANDIDATE_UPDATE_STATUS,
  JOB_UPDATE,
} from "../../../../../../ApiUrl";
import {
  Button,
  Card,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Table,
} from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  CandidatesStatusList,
  companyStatusList,
  Courses,
  statusList,
} from "../../../../../pages/constant/constant";
import Filters from "../../../../filter";
import { getFormatDate } from "../../../../../pages/utils/helpers";
import Notes from "../../../../Modals/Notes";
import { useCallAPI } from "../../../../../../hooks/useCallAPI";
import { DeleteOutlined } from "@material-ui/icons";
import {
  deleteAllCandidates,
  deleteCandidates,
} from "../../../../../../redux/reducers/candidatesSlice";
import { Option } from "antd/lib/mentions";
import { CustomSnackbar } from "../../../../others/Snackbar";
import Paragraph from "antd/lib/typography/Paragraph";

const { Search } = Input;

const CandidateList = ({ token, role, common }) => {
  const dispatch = useDispatch();
  const [candidateListDetails, setCandidateListDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [openDltModal, setOpenDltModal] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [callBackParam, setCallBackParam] = useState({});
  const location = useLocation();
  const { record } = location.state || {};
  const [selectedLabels, setSelectedLabels] = useState({});

  const [expand, setExpand] = useState(false);

  const [showNotes, setShowNotes] = useState(false);
  const NotesData = useCallAPI(CANDIDATE_NOTES);
  const [selectedRow, setSelectedRow] = useState(null);
  const users = useSelector(({ users }) => users);
  const UpdateNotes = useCallAPI(CANDIDATE_NOTE_UPDATE);
  const DeleteNotes = useCallAPI(CANDIDATE_NOTE_DELETE);
  const UpdateCandidate = useCallAPI(CANDIDATE_UPDATE_PARTIALLY);
  const UpdateCandidateStatus = useCallAPI(CANDIDATE_UPDATE_STATUS);
  const UpdateCandidateLabel = useCallAPI(CANDIDATE_UPDATE_LABEL);
  const rolePath =
    users.role === "recruitmentmanager"
      ? "recruitment-manager"
      : users.role === "bdmmanager"
      ? "bdm-manager"
      : users.role;

  const handleNotes = (value) => {
    UpdateCandidate.patch({ multipleNotes: value }, selectedRow._id);
  };
  const handleUpdateNotes = (value, id) => {
    UpdateNotes.patch(value, `${selectedRow._id}/${id}`);
  };
  const handleDeleteNotes = (id) => {
    DeleteNotes.deletee(`${selectedRow._id}/${id}`);
  };

  const handleCancelDeleteModal = () => {
    setOpenDltModal(false);
  };

  const getNotes = (record) => {
    console.log(record);
    if (record?._id) {
      NotesData.get(record?._id);
      setSelectedRow(record);
      setShowNotes(true);
    }
  };
  // useEffect(() => {
  //   if (record) {
  //     getOpeningDetail();
  //   }
  // }, [record]);

  const getCategory = useCallback(
    (value) => {
      const categoryList = common?.category;
      return categoryList?.find((item) => item.code === value);
    },
    [common]
  );

  const handleLabelChange = async (candidateId, oldLabel, newLabel) => {
    setSelectedLabels((prev) => ({
      ...prev,
      [candidateId]: newLabel,
    }));

    await UpdateCandidateLabel.post({
      candidateId,
      oldLabel,
      newLabel,
    });
  };

  const fetchUserData = useCallback(
    (params) => {
      setLoading(true);
      if (params) {
        axios
          .post(CANDIDATE, params, {
            headers: { Authorization: token },
          })
          .then((resp) => {
            setCandidateListDetails(resp.data.data.candidate_list_details);
            setTotalPages(resp.data.data.totalPages);
            setTotalRecords(resp.data.data.totalRecords);
            setLoading(false);
          })
          .catch((error) => {});
      }
    },
    [token]
  );
  const handleDeleteCandidate = async () => {
    setIsDeleting(true);
    try {
      const resultAction = await dispatch(
        deleteAllCandidates({
          candidateIds: idsToDelete,
          // jobId: record.opening_id,
        })
      );

      if (deleteAllCandidates.fulfilled.match(resultAction)) {
        fetchUserData(callBackParam);
        message.success("Candidate(s) deleted successfully");

        setOpenDltModal(false);
      } else if (deleteAllCandidates.rejected.match(resultAction)) {
        throw new Error(
          resultAction.error.message || "Failed to delete candidate(s)"
        );
      }
    } catch (error) {
      console.error("Error deleting candidate:", error);
      message.error(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const callBack = useCallback(
    (value) => {
      const param = {
        current_page: currentPage,
        per_page: pageSize,
        order: "created_at",
        order_direction: "desc",
        search: search,
        job_post_title: "",
        dateRange: value?.arrayDateRange ? value?.arrayDateRange : [],
        categories: value?.categories ? value?.categories : [],
        status: value.status,
        labels: value.selectedLabel ? value.selectedLabel : [],
        payType: value?.selectedPayType,
        gender: value?.selectedGender,
        experience: value?.selectedExperience,
        isRemote: value?.isRemoteChecked,
        skills: value?.selectedSkills,
        booleanSearch: value?.booleanSearch,
        jobTitle: value?.jobTitle,
        // noticePeriod: value?.selectedNoticePeriod,
        university: value?.selectedUniversity,
        course: value?.selectedCourse?.toString(),
        location: value?.location,
      };

      setCallBackParam(param);
      fetchUserData(param);
    },
    [currentPage, pageSize, search, fetchUserData]
  );

  // useEffect(() => {
  //   console.log("hello from data..");
  //   fetchUserData(callBackParam);
  // }, [callBackParam, fetchUserData]);

  const onSearch = (value) => {
    setSearch(value);
    if (!value) return;
    callBack(callBackParam);
  };

  const openDeleteModal = (candidateIds) => {
    setOpenDltModal(true);
    setIdsToDelete(Array.isArray(candidateIds) ? candidateIds : [candidateIds]);
  };
  const createNewClientPath =
    role === "admin"
      ? "admin"
      : role === "recruitmentmanager"
      ? "recruitment-manager"
      : role === "bdmmanager"
      ? "bdm-manager"
      : role;

  const columns = [
    {
      // title: "Opening Title",
      dataIndex: "_id",
      key: "notes",
      // show: columnVisibility.notes,
      // fixed: "left",
      mandatory: true,
      key: () => {
        this.dataIndex + 1;
      },
      render: (data, record) => {
        // return date ? getFormatDate(date) : "";
        // console.log('get notes in column', record,);
        return (
          <Button
            onClick={() => {
              getNotes(record);
            }}
          >
            N
          </Button>
        );
      },
      // width: " .4%",
    },
    {
      title: "Display Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Current Location",
      dataIndex: "current_location",
      key: "current_location",
    },
    {
      title: "Years of Experience",
      dataIndex: "total_work_exp_year",
      key: "total_work_exp_year",
    },
    {
      title: "Profile Summary",
      dataIndex: "profile_summary",
      key: "profile_summary",
      key: () => {
        this.dataIndex;
      },
      render: (profile_summary) => {
        if (!profile_summary) return <></>;
        return (
          <>
            <Paragraph
              ellipsis={
                !expand
                  ? {
                      expandable: false,
                      rows: 2,
                    }
                  : false
              }
            >
              {profile_summary}
            </Paragraph>
            <Button type="link" onClick={() => setExpand((prev) => !prev)}>
              {expand ? "less" : "more"}
            </Button>
          </>
        );
      },
    },
    {
      title: "LinkedIn",
      // show: columnVisibility.linkedIn,
      key: "linkedinUrl",
      key: () => {
        this.dataIndex;
      },
      sorter: true,
      render: (text, records) => (
        <span>
          {records?.linkedinUrl ? (
            <a href={records?.linkedinUrl} target="_blank">
              <Button type="primary" icon={<EyeOutlined />} />
            </a>
          ) : null}
        </span>
      ),
    },
    {
      title: "Job Category",
      dataIndex: "job_category",
      key: "job_category",
      render: (code) => {
        const category = getCategory(code);
        return category ? category.name : "";
      },
    },
    {
      title: "Pay Type",
      dataIndex: "pay_type",
      key: "pay_type",
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      render: (code, row) => {
        const qualification = row?.candidate_qualifications[0];
        if (qualification) {
          const course = Courses.find(
            (course) => course.value == qualification.course
          );
          return course?.label;
        }
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      render: (code, row) => {
        const employment = row?.employees[0];
        if (!employment) return;
        return employment.designation;
        // if (employment) {
        //   const course = Courses.find(
        //     (course) => course.value == employment.course
        //   );
        //   return course.label;
        // }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (Code, record, index) => {
        return (
          <Select
            placeholder="Select Status Name"
            value={Code}
            onChange={async (status) => {
              await UpdateCandidateStatus.post({
                status,
                candidateId: record._id,
              });
              await fetchUserData(callBackParam);
              // await UpdateOpeningStatus.put({ status, status }, record._id);
              // callJobOpenings();
            }}
            style={{ width: "100%" }}
          >
            <Option value={""}>{"Select"}</Option>
            {CandidatesStatusList !== undefined &&
              CandidatesStatusList.map((status, index) => (
                <Option key={index.toString()} value={status.value}>
                  {status.label}
                </Option>
              ))}
          </Select>
        );
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text) => {
    //     const item = statusList?.find(
    //       (status) => text?.toUpperCase() === status.value.toUpperCase()
    //     );
    //     return item ? item.label : "text";
    //   },
    // },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => {
        return date ? getFormatDate(date) : "";
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <span>
          <Space style={{ marginRight: "5px" }} size="middle">
            <NavLink
              to={{
                pathname: `/${rolePath}/edit-candidate`,
                state: {
                  item: {
                    ...record,
                    opening_id: "",
                  },
                },
              }}
            >
              <Button type="primary" icon={<EditOutlined />} />
            </NavLink>
            <NavLink
              to={{
                pathname: `/${createNewClientPath}/candidateProfileDetail`,
                state: { item: record },
              }}
            >
              <Button type="primary" icon={<EyeOutlined />} />
            </NavLink>
            {users.role === "admin" && (
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => openDeleteModal(record._id)}
              />
            )}
          </Space>
          {role === "bdm" && (
            <Space size="middle">
              <NavLink
                to={{
                  pathname: `/${createNewClientPath}/edit-candidate`,
                  state: { item: record },
                }}
              >
                <Button type="primary" icon={<EditOutlined />} />
              </NavLink>
            </Space>
          )}
          <Space>
            <Radio.Group
              value={
                selectedLabels[record._id] ||
                record?.tags?.find((tag) =>
                  ["top", "good", "qualified"].includes(tag)
                ) ||
                ""
              }
              onChange={async (e) => {
                let oldLabel =
                  record.tags.find((tag) =>
                    ["top", "good", "qualified"].includes(tag)
                  ) || "";
                let newLabel = e.target.value;

                await handleLabelChange(record._id, oldLabel, newLabel);
              }}
              size="small"
            >
              <Radio.Button value="top">Best</Radio.Button>
              <Radio.Button value="good">Good</Radio.Button>
              <Radio.Button value="qualified">Qualified</Radio.Button>
            </Radio.Group>
          </Space>
        </span>
      ),
    },
  ];

  return (
    <>
      <Filters
        callBack={callBack}
        showCategory={true}
        showCompany={false}
        clearSearch={onSearch}
        statusObject={companyStatusList}
      />
      <CustomSnackbar
        success={UpdateCandidateStatus.success || UpdateCandidateLabel.success}
        error={UpdateCandidateStatus.error || UpdateCandidateLabel.error}
      />
      <Modal
        title={`Delete Candidate${idsToDelete.length > 1 ? "s" : ""}`}
        open={openDltModal}
        onOk={handleDeleteCandidate}
        okText="Delete"
        cancelText="Cancel"
        confirmLoading={isDeleting}
        onCancel={handleCancelDeleteModal}
      >
        <h5>
          Are you sure you want to delete{" "}
          {idsToDelete.length > 1 ? "these candidates" : "this candidate"}?
        </h5>
        <p style={{ color: "red" }}>This cannot be undone!</p>
      </Modal>
      <Notes
        handleCancel={() => setShowNotes(false)}
        isVisible={showNotes}
        handleNotes={handleNotes}
        notes={NotesData}
        jobOpenings={selectedRow}
        user={users}
        updateNoteState={setShowNotes}
        handleUpdateNote={handleUpdateNotes}
        handleDeleteNote={handleDeleteNotes}
        notesField={"multipleNotes"}
      />
      <br />
      <Card
        title="All Candidate List"
        bordered={false}
        className="px-0 py-0"
        extra={
          <NavLink to={`/${createNewClientPath}/addCandidate`}>
            <Button type="primary">Add Candidate</Button>
          </NavLink>
        }
      >
        <Search
          value={search}
          placeholder="Search..."
          onSearch={onSearch}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 200 }}
        />
        <Table
          loading={loading}
          scroll={{ x: 200 }}
          pagination={{
            total: totalRecords,
            showSizeChanger: false,
            current: currentPage,
            pageSize: pageSize,
            onChange: (current) => {
              setCurrentPage(current);
              fetchUserData({ ...callBackParam, current_page: current });
            },
          }}
          dataSource={candidateListDetails}
          columns={columns}
        />
      </Card>
    </>
  );
};

// Map Redux state to React component props
const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    role: state.users.role,
    common: state.common,
  };
};

export default connect(mapStateToProps)(CandidateList);
