import React, { useState, useEffect } from "react";
import {
  Card,
  DatePicker,
  Col,
  Row,
  Select,
  Button,
  Modal,
  Collapse,
  Checkbox,
  Radio,
  notification,
  Input,
  Tag,
} from "antd";
import { DownOutlined, FilterOutlined } from "@ant-design/icons";
import {
  CandidatesStatusList,
  Courses,
  experienceRanges,
  noticePeriod,
  OpeningStatusList,
  Universities,
} from "../../pages/constant/constant";
import { useSelector } from "react-redux";
import axios from "axios";
import { COL } from "../calrow";
import {
  COMPANY_NAME_LIST,
  COMPANY_NAME_LIST_RECRUITER,
} from "../../../ApiUrl";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const Filters = ({
  callBack,
  showCategory,
  showCompany,
  showOpening,
  clearSearch,
  statusObject,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [openingTitleList, setOpeningTitleList] = useState([]);
  const [status, setStatus] = useState("");
  const [dateRangeValue, setDateRangeValue] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [opening_id, setOpeningId] = useState("");
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [selectedPayType, setSelectedPayType] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const skillsOptions = [
    "JavaScript",
    "React",
    "Node.js",
    "Python",
    "Java",
    "Html",
    "Css",
  ];
  const users = useSelector(({ users }) => users);
  const common = useSelector(({ common }) => common);
  const categoryList = common.category;
  const payTypes = ["Hourly", "Daily", "Weekly", "Monthly", "Yearly"];
  const [isRemoteChecked, setIsRemoteChecked] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedNoticePeriod, setSelectedNoticePeriod] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [booleanSearch, setBooleanSearch] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [location, setLocation] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Temporary state for advanced filters
  const [tempGender, setTempGender] = useState(null);
  const [tempExperience, setTempExperience] = useState(null);
  const [tempSkills, setTempSkills] = useState([]);
  const [tempRemote, setTempRemote] = useState(false);
  const [tempPayType, setTempPayType] = useState(null);
  const [tempNoticePeriod, setTempNoticePeriod] = useState(null);
  const [tempCourse, setTempCourse] = useState(null);
  const [tempUniversity, setTempUniversity] = useState(null);
  const [tempBooleanSearch, setTempBooleanSearch] = useState(null);
  const [tempJobTitle, setTempJobTitle] = useState(null);
  const [tempLocation, setTempLocation] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleTagClose = (filterName, value) => {
    setSelectedFilters(
      selectedFilters.filter(
        (filter) => !(filter.name === filterName && filter.value === value)
      )
    );
    if (filterName == "Gender") {
      setTempGender("");
    } else if (filterName == "Course") {
      setTempCourse("");
    } else if (filterName == "PayType") {
      setTempPayType("");
    } else if (filterName == "NoticePeriod") {
      setTempNoticePeriod("");
    } else if (filterName == "Experience") {
      setTempExperience("");
    }
  };

  const addFilterTag = (filterName, value) => {
    console.log(value)
    if (value) {

      // Check if the tag already exists
      const existingTagIndex = selectedFilters.findIndex(
        (filter) => filter.name === filterName
      );

      if (existingTagIndex !== -1) {
        // If tag exists, update its value
        const updatedFilters = [...selectedFilters];
        updatedFilters[existingTagIndex] = { name: filterName, value };
        setSelectedFilters(updatedFilters);
      } else {
        // If tag does not exist, add a new one
        setSelectedFilters([...selectedFilters, { name: filterName, value }]);
      }
    }
  };

  // console.log(tempCourse,tempUniversity)
  const statusList = statusObject ? statusObject : OpeningStatusList;

  useEffect(() => {
    if (users.role === "bdm" || users.role === "recruiter") getAllCompanyList();
    if (users.role === "company") getOpeningTitleList();
  }, []);

  useEffect(() => {
    const paramValues = {
      arrayDateRange: dateRange.length > 0 ? [dateRange[0], dateRange[1]] : [],
      categories,
      status,
      selectedCompany,
      opening_id,
      selectedLabel,
      selectedPayType,
      selectedGender,
      selectedExperience,
      selectedSkills,
      isRemoteChecked,
      selectedNoticePeriod,
      selectedCourse,
      selectedUniversity,
      booleanSearch,
      jobTitle,
      location,
    };
    callBack(paramValues);
  }, [
    dateRange,
    categories,
    status,
    selectedCompany,
    selectedLabel,
    selectedPayType,
    selectedGender,
    selectedExperience,
    isRemoteChecked,
    selectedExperience,
    selectedSkills,
    selectedNoticePeriod,
    selectedCourse,
    selectedUniversity,
    booleanSearch,
    jobTitle,
    location,
  ]);

  const getAllCompanyList = () => {
    const URL =
      users.role === "bdm" ? COMPANY_NAME_LIST : COMPANY_NAME_LIST_RECRUITER;
    const params =
      users.role === "bdm"
        ? { bdm_id: users.user._id }
        : { recruiter_id: users.user._id };
    axios
      .post(URL, params, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        setCompanyList(res.data.data);
      })
      .catch((error) => {});
  };

  const getOpeningTitleList = () => {
    // Uncomment and implement as needed
    // const params = { company_id: users.user._id };
    // axios
    //   .post(OPENING_TITLE_LIST, params, {
    //     headers: { Authorization: users.token },
    //   })
    //   .then((res) => {
    //     setOpeningTitleList(res.data.data.opening_detail_list);
    //   })
    //   .catch((error) => {});
  };

  const onChange = (value, dateString) => {
    setDateRangeValue(value);
    setDateRange(dateString[0] ? dateString : []);
  };

  const handleCategoryChange = (value) => {
    if (value.length > 2) return; // Limit selection
    setCategories(value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
  };

  const handleOpeningIdChange = (value) => {
    setOpeningId(value);
  };

  const handleLabelChange = (value) => {
    setSelectedLabel(value);
  };

  const onResetFilters = () => {
    setDateRange([]);
    setCategories([]);
    setStatus("");
    setSelectedLabel([]);
    setSelectedCompany("");
    setOpeningId("");
    setIsRemoteChecked(false);
    setSelectedSkills([]);
    setSelectedGender(null);
    setSelectedExperience(null);
    setSelectedNoticePeriod(null);
    setTempExperience(null);
    setTempGender("");
    setTempRemote(false);
    setTempPayType("");
    setTempNoticePeriod(null);
    setTempLocation(null);
    setTempCourse(null);
    setTempUniversity(null);
    setTempBooleanSearch("");
    setBooleanSearch("");
    setTempJobTitle("");
    setJobTitle("");

    if (clearSearch) {
      clearSearch("");
    }
  };

  const handlePayTypeChange = (payType) => {
    setTempPayType(payType);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Apply the advanced filter values to the main states
    setSelectedGender(tempGender);
    setSelectedExperience(tempExperience);
    setSelectedSkills(tempSkills);
    setIsRemoteChecked(tempRemote);
    setSelectedPayType(tempPayType);
    setSelectedNoticePeriod(tempNoticePeriod);
    setLocation(tempLocation);
    setSelectedUniversity(tempUniversity);
    setSelectedCourse(tempCourse);
    setBooleanSearch(tempBooleanSearch);
    setJobTitle(tempJobTitle);
    setIsModalVisible(false);
    // notification.success({
    //   message: "Filters Applied",
    //   description: "Your filters have been successfully applied.",
    // });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const FiltersDropdown = () => (
    <Button className="rounded" onClick={showModal}>
      Advanced Filters <FilterOutlined />
    </Button>
  );

  const handleExperienceChange = (value) => {
    const selectedExp = experienceRanges.find((exp) => exp.label === value);
    setTempExperience(selectedExp); // set temporary experience
  };

  return (
    <Card title="Filters" bordered={false} className="px-0 py-0">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <COL cal={8}>
          <label htmlFor="by_source" title="Date Range">
            Date Range :
          </label>
          <br />
          <RangePicker
            className="mb-3"
            value={dateRangeValue}
            onChange={onChange}
          />
        </COL>

        {showCategory && (
          <COL cal={6}>
            <label htmlFor="by_source" title="Category">
              Category :
            </label>
            <br />
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Category Name"
              maxTagCount={5}
              onChange={handleCategoryChange}
              value={categories}
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {categoryList &&
                categoryList.map((category, index) => (
                  <Option key={index.toString()} value={category.code}>
                    {category.name}
                  </Option>
                ))}
            </Select>
          </COL>
        )}

        <COL cal={6}>
          <label htmlFor="by_source" title="Status">
            Status :
          </label>
          <br />
          <Select
            placeholder="Select Status Name"
            value={status}
            onChange={handleStatusChange}
            style={{ width: "100%" }}
          >
            <Option value="">{"Select"}</Option>
            {CandidatesStatusList &&
              CandidatesStatusList.map((status, index) => (
                <Option key={index.toString()} value={status.value}>
                  {status.label}
                </Option>
              ))}
          </Select>
        </COL>

        <COL cal={6}>
          <label htmlFor="by_source" title="Label">
            Label :
          </label>
          <br />
          <Select
            placeholder="Filter Label"
            onChange={handleLabelChange}
            mode="multiple"
            style={{ minWidth: "200px" }}
            showSearch
          >
            <Select.Option value="top" label="Best">
              <div>Best Candidates</div>
            </Select.Option>
            <Select.Option value="good" label="Good">
              <div>Good Candidates</div>
            </Select.Option>
            <Select.Option value="bad" label="Bad">
              <div>Bad Candidates</div>
            </Select.Option>
          </Select>
        </COL>

        <Col cal={6}>
          <br />
          <FiltersDropdown />
        </Col>
      </Row>

      <Row gutter={24} className="py-5 text-right">
        <Col span={24}>
          <Button
            onClick={() => onResetFilters()}
            type="primary"
            size="small"
            danger
          >
            Clear Filters
          </Button>
        </Col>
      </Row>
      <Modal
        title="Advanced Filters"
        visible={isModalVisible}
        bodyStyle={{
          maxHeight: "600px",
          overflowY: "auto",
          padding: "24px",
        }}
        width={800}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Apply Filters
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            marginBottom: "16px",
          }}
        >
          {selectedFilters.map((filter, index) => (
            <Tag
              key={index}
              closable
              onClose={() => handleTagClose(filter.name, filter.value)}
            >
              {`${filter.name}: ${filter.value}`}
            </Tag>
          ))}
        </div>

        <div style={{ display: "flex", gap: "24px", marginBottom: "16px" }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Job Title
            </label>
            <Input
              onChange={(event) => {
                setTempJobTitle(event.target.value);
                // addFilterTag('Job Title', event.target.value);
              }}
              placeholder="Enter Job title"
              style={{ width: "100%" }}
              value={tempJobTitle}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Boolean Search
            </label>
            <Input
              onChange={(event) => {
                setTempBooleanSearch(event.target.value);
                // addFilterTag('Boolean Search', event.target.value);
              }}
              placeholder="Write your query"
              style={{ width: "100%" }}
              value={tempBooleanSearch}
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "24px", marginBottom: "16px" }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Gender
            </label>
            <Radio.Group
              onChange={(e) => {
                setTempGender(e.target.value);
                addFilterTag("Gender", e.target.value);
              }}
              value={tempGender}
              style={{ width: "100%" }}
            >
              <Radio value={"Male"}>Male</Radio>
              <Radio value={"Female"}>Female</Radio>
              <Radio value={"Other"}>Other</Radio>
            </Radio.Group>
          </div>

          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Experience
            </label>
            <Select
              showSearch
              placeholder="Select Experience"
              style={{ width: "100%" }}
              onChange={(value) => {
                setTempExperience(value);
                
                addFilterTag("Experience", value);
              }}
              value={tempExperience}
            >
              {experienceRanges.map((expRange, index) => (
                <Option key={index.toString()} value={expRange.label}>
                  {expRange.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ display: "flex", gap: "24px", marginBottom: "16px" }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Pay Type
            </label>
            <Select
              placeholder="Select Pay Type"
              style={{ width: "100%" }}
              onChange={(value) => {
                setTempPayType(value);
                addFilterTag("PayType", value);
              }}
              value={tempPayType}
            >
              {payTypes.map((payType, index) => (
                <Option key={index.toString()} value={payType}>
                  {payType}
                </Option>
              ))}
            </Select>
          </div>

          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Skills
            </label>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Select or add skills"
              onChange={(value) => {
                setTempSkills(value);
                addFilterTag("Skills", value.join(", "));
              }}
              value={tempSkills}
            >
              {tempSkills.map((skill) => (
                <Option key={skill} value={skill}>
                  {skill}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ display: "flex", gap: "24px", marginBottom: "16px" }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Location
            </label>
            <Checkbox
              checked={tempRemote}
              onChange={(e) => {
                setTempRemote(e.target.checked);
                addFilterTag("Remote", e.target.checked ? "Yes" : "No");
              }}
            >
              Remote
            </Checkbox>
          </div>

          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Notice Period
            </label>
            <Select
              showSearch
              placeholder="Select Notice Period"
              style={{ width: "100%" }}
              onChange={(value) => {
                setTempNoticePeriod(value);
                addFilterTag("NoticePeriod", value);
              }}
              value={tempNoticePeriod}
            >
              {noticePeriod.map((period, index) => (
                <Option key={index.toString()} value={period.value}>
                  {period.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ display: "flex", gap: "24px", marginBottom: "16px" }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Location
            </label>
            <Input
              placeholder="Enter Location"
              onChange={(e) => {
                setTempLocation(e.target.value);
                addFilterTag("Location", e.target.value);
              }}
              value={tempLocation}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", gap: "24px", marginBottom: "16px" }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              University
            </label>
            <Input
              placeholder="Enter University"
              onChange={(e) => {
                setTempUniversity(e.target.value);
                addFilterTag("University", e.target.value);
              }}
              value={tempUniversity}
              style={{ width: "100%" }}
            />
          </div>

          <div style={{ flex: 1 }}>
            <label style={{ display: "block", marginBottom: "8px" }}>
              Course
            </label>
            <Select
              showSearch
              placeholder="Select Course"
              onChange={(value) => {
                setTempCourse(value);

                // Find the label corresponding to the selected value
                const selectedCourse = Courses.find(
                  (course) => course.value === value
                );
                const courseLabel = selectedCourse ? selectedCourse.label : "";

                // Pass both the label and value to addFilterTag
                addFilterTag("Course", courseLabel);
              }}
              value={tempCourse}
              style={{ width: "100%" }}
            >
              {Courses.map((course) => (
                <Option key={course.value} value={course.value}>
                  {course.label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default Filters;
