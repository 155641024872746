/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Card,
  Radio,
  Modal,
  Typography,
  DatePicker,
  Space,
  Button,
  Spin,
  Avatar,
  Image,
  Empty,
  TimePicker,
  Tag,
  Pagination,
  Table,
  Tooltip,
  InputNumber,
  Tabs,
} from "antd";
import {
  ColumnHeightOutlined,
  DownloadOutlined,
  EditFilled,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment, { duration } from "moment";
import {
  optionEmploymentType,
  Courses,
  CandidateSubmissionStatus,
  months,
  noticePeriod,
  optionsInterviewType,
} from "../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  IMAGE_CANDIDATE_URL,
  CANDIDATE,
  COMPANY_CANDIDATE_DETAILS,
  COMPANY_INTERVIEW_SCHEDULE,
  CANDIDATE_CV_UPLOAD,
  CANDIDATE_UPDATE_PARTIALLY,
  ASSIGNED_RECRUITER_LIST,
  ASSIGNED_BDM_LIST,
  SUBMISSION_BY_RECRUITER,
  JOB_ASSIGNMENT_DETAILS,
  CANDIDATE_NOTE_DELETE,
  CANDIDATE_NOTE_UPDATE,
  CANDIDATE_NOTES,
  CANDIDATE_SUBMISSION_STATUS,
  UPDATE_INTERVIEW_SCHEDULE,
} from "../../../ApiUrl";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import RoomIcon from "@material-ui/icons/Room";
import CallIcon from "@material-ui/icons/Call";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import EmailIcon from "@material-ui/icons/Email";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { SmileOutlined } from "@ant-design/icons";
import {
  PostContactActivity,
  CONTACT_ACTIVITY_MODULE,
} from "./ActivityLogApiCall";
import { showError } from "../utils/helpers";
import PerfectScrollbar from "react-perfect-scrollbar";
import { requestHeader } from "../../../others/header";

import { logoutHandler } from "../../../others/logout";
import { setShouldRefresh } from "../../../redux/reducers/candidatesSlice";
import { useCallAPI } from "../../../hooks/useCallAPI";
import Search from "antd/lib/transfer/search";
import { handleSearch } from "../../../others/handler/handleSearch";
import TabPane from "antd/lib/tabs/TabPane";
import Notes from "../../modules/Modals/Notes";
import { render } from "@testing-library/react";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const { Text, Title } = Typography;
const { Option } = Select;
const FormItem = Form.Item;

function CandidateProfileDetail(props) {
  const formDetails = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [editVisibleDetails, setEditVisibleDetails] = useState(false);
  const [interviewRecord, setInterviewRecord] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [dateValue, setDateValue] = useState();
  const [timeValue, setTimeValue] = useState();
  const [notes, setNotes] = useState("");
  const [metaView, setMetaView] = useState("");
  let location = useLocation();
  const users = useSelector(({ users }) => users);
  const common = useSelector(({ common }) => common);
  const categoryList = common.category;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const AssignedJobs = useCallAPI(ASSIGNED_RECRUITER_LIST);
  const AssignedBDMJobs = useCallAPI(ASSIGNED_BDM_LIST);
  const timeoutRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const UpdateNotes = useCallAPI(CANDIDATE_NOTE_UPDATE);
  const DeleteNotes = useCallAPI(CANDIDATE_NOTE_DELETE);
  const NotesData = useCallAPI(CANDIDATE_NOTES);
  const [selectedRow, setSelectedRow] = useState(null);
  const UpdateCandidate = useCallAPI(CANDIDATE_UPDATE_PARTIALLY);
  const [openingId, setOpeningId] = useState(null);
  const [recruiterId, setRecruiterId] = useState(null);
  const [bdmId, setBdmId] = useState(null);

  const handleEditInterviewRecord = (record) => {
    console.log(record);
    setInterviewRecord(record);
  };

  useEffect(() => {
    if (formDetails?.current && interviewRecord) {
      formDetails.current.setFieldsValue({
        ...interviewRecord,
      });
    }
  }, [interviewRecord, formDetails]);

  const submitCandidateToJob = (openingDetails, bdmId) => {
    let candidate_submission_by_recruiter = [];
    const candidateId = location.state.item.candidate_id
      ? location.state.item.candidate_id
      : location.state.item._id;
    if (!bdmId) {
      console.log("bdm not found returning");
      return;
    }

    const candidateToSubmit = {
      opening_id: openingDetails.opening_id,
      candidate_id: candidateId,
      recruiter_id: users.user._id,
      // company_id:
      //   openingDetails.account_name.length > 0
      //     ? openingDetails.account_name[0]._id
      //     : null,
      bdm_id: bdmId,
      submission_status: "submission",
    };

    candidate_submission_by_recruiter = [candidateToSubmit];

    let param = {
      candidate_submission_by_recruiter: candidate_submission_by_recruiter,
    };

    setLoading(true);
    axios
      .post(SUBMISSION_BY_RECRUITER, param, {
        headers: { Authorization: users.token },
      })
      .then((res) => {
        if (!res.data.error) {
          setIsModalVisible(false);
          setLoading(false);
          getCandidateDetail();
          // setSuccess(true);
          // setMsgSuccess(res.data.message);
          // setLoading(false);
          // setError(false);
          // setDefaultState();
          // getOpeningDetail();
        }
      })
      .catch((error) => {
        // setSuccess(false);
        // setLoading(false);
        // setError(true);
        // if (error.response) {
        //   let errorMessage = "";
        //   {
        //     error.response.data &&
        //       Object.entries(error.response.data.errors).map(
        //         ([key, value]) => {
        //           return (errorMessage += value + ", ");
        //         }
        //       );
        //   }
        //   setmsgError(errorMessage);
        // }
        // if (error?.response?.status == 401) {
        //   logoutHandler(history);
        // }
        // setDefaultState();
      });
  };
  const handleUpdateNotes = (value, id) => {
    UpdateNotes.patch(value, `${selectedRow._id}/${id}`);
  };
  const handleDeleteNotes = (id) => {
    DeleteNotes.deletee(`${selectedRow._id}/${id}`);
    getNotes(selectedRow);
  };

  const callGetAssignedJobs = async () => {
    const candidateId = location.state.item.candidate_id
      ? location.state.item.candidate_id
      : location.state.item._id;
    const param = {
      current_page: pagination.current,
      per_page: pagination.pageSize,
      order_direction: "desc",
      search: search,
      order: "created_at",
      candidate_id: candidateId,
      // other params as needed
    };

    if (
      users.role == "recruiter" ||
      users.role == "freelancerecruiter" ||
      users.role == "admin"
    ) {
      console.log("hello.. inside..");
      param.recruiter_id = users.user._id;
      if (!AssignedJobs.loading) {
        const response = await AssignedJobs.post(param);
      }
    }
  };

  const handleNotes = (value) => {
    UpdateCandidate.patch({ multipleNotes: value }, selectedRow._id);
  };

  useEffect(() => {
    if (AssignedJobs.data) {
      setJobData(AssignedJobs.data.data.job_opening_listing);
      setPagination({
        ...pagination,
        total: AssignedJobs.data.data.total,
      });
    }
  }, [AssignedJobs.data]);

  const openSubmitToJobModal = () => {
    setIsModalVisible(true);
  };

  const handlePaginationChange = (page) => {
    setPagination({ ...pagination, current: page });
    callGetAssignedJobs();
  };

  const getNotes = (record) => {
    if (record?._id) {
      NotesData.get(record?._id);
      setSelectedRow(record);
      setShowNotes(true);
    }
  };

  useEffect(() => {
    getCandidateDetail();
    // Fetch data when modal opens
  }, [users]);

  useEffect(() => {
    callGetAssignedJobs();
  }, [search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (
        // users.role !== "recruiter" &&
        // users.role !== "freelancerecruiter" &&
        notes &&
        notes !== userProfile.data[0].notes
      ) {
        await updateNotes();
      }
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [notes]);

  const updateNotes = async () => {
    try {
      await axios.patch(
        `${CANDIDATE_UPDATE_PARTIALLY}/${userProfile.data[0]._id}`,
        { notes },
        requestHeader()
      );
    } catch (error) {
      if (error?.response?.status == 401) {
        logoutHandler(history);
      }

      // if (error.response) {
      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2xx
      //   // console.error('Status Code:', error.response.status);
      //   // console.error('Response Data:', error.response.data);

      // } else if (error.request) {
      //   // The request was made but no response was received
      //   // console.error('No response received:', error.request);
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   // console.error('Error:', error.message);
      // }
    }
  };

  const getCandidateDetail = () => {
    if (location.state) {
      const openingId = location.state
        ? location.state.item.opening_details
          ? location.state.item.opening_details.opening_id
          : location.state.item.opening_id
        : "";

      const candidateId = location.state.item.candidate_id
        ? location.state.item.candidate_id
        : location.state.item._id;

      let param = {
        candidate_id: candidateId,
        opening_id: openingId,
      };

      setLoading(true);
      axios
        .post(COMPANY_CANDIDATE_DETAILS, param, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          if (!res.data.error) {
            // console.log(res.data.data[0]);
            setUserProfile(res.data);
            getNotes(res.data.data[0]);
            setNotes(res.data.data[0].notes);
            setLoading(false);
            setError(false);
            setDefaultState();
          }
        })
        .catch((error) => {
          setSuccess(false);
          setLoading(false);
          setError(true);
          if (error.response) {
            let errorMessage = "";
            {
              error.response.data &&
                Object.entries(error.response.data.errors).map(
                  ([key, value]) => {
                    return (errorMessage += value + ", ");
                  }
                );
            }
            setmsgError(errorMessage);
          }

          setDefaultState();
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    }
  };

  const callActivity = (title, description) => {
    if (users.user.contact_person_details) {
      const contact_person_details = users.user.contact_person_details;
      const contactLogObject = {
        company_id: users.user._id,
        contact_id: users.user.contact_person_details._id,
        module: CONTACT_ACTIVITY_MODULE.SUBMISSION,
        title: title,
        description:
          description +
          contact_person_details.first_name +
          " " +
          contact_person_details.last_name,
      };
      PostContactActivity(contactLogObject, users.token);
    }
  };

  const updateCandidateStatus = (record, status) => {
    if (record !== undefined) {
      const statusObject = CandidateSubmissionStatus.find((item) => {
        return status === item.value;
      });

      const submission_status = statusObject
        ? statusObject?.label
        : status.charAt(0, 1).toUpperCase() + status.slice(1);

      const openingId = location.state
        ? location.state.item.opening_details
          ? location.state.item.opening_details.opening_id
          : ""
        : "";

      setSuccess(false);
      // setLoading(true);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
      // axios
      //   .put(
      //     CANDIDATE + "/status/" + record._id,
      //     {
      //       status: status,
      //       opening_id: openingId,
      //     },
      //     {
      //       headers: { Authorization: users.token },
      //     }
      //   )
      //   .then((res) => {
      //     if (!res.data.error) {
      //       onClose();
      //       callActivity(
      //         record.first_name +
      //           " " +
      //           record.last_name +
      //           " Submission status update to " +
      //           submission_status,

      //         record.first_name +
      //           " " +
      //           record.last_name +
      //           " Submission status update to " +
      //           submission_status +
      //           " by "
      //       );
      //       setSuccess(true);
      //       setLoading(false);
      //       setError(false);
      //       setMsgSuccess(res.data.message);
      //       setDefaultState();
      //       dispatch(setShouldRefresh(true));
      //       setTimeout(() => {
      //         props.history.goBack();
      //       }, 6000);
      //     }
      //   })
      //   .catch((error) => {
      //     let errorMessage = "";
      //     {
      //       Object.entries(error.response.data.errors).map(([key, value]) => {
      //         return (errorMessage += value + ", ");
      //       });
      //     }
      //     setSuccess(false);
      //     setLoading(false);
      //     setError(true);
      //     setmsgError(errorMessage);
      //     setDefaultState();
      //     if (error?.response?.status == 401) {
      //       logoutHandler(history);
      //     }
      //   });
    }
  };

  const updateInterviewStatus = (record, status, values) => {
    if (record !== undefined || interviewRecord) {
      const opening_details = location.state
        ? location.state.item.opening_details
        : "";

      const candidateId = location.state.item.candidate_id
        ? location.state.item.candidate_id
        : location.state.item._id;

      const job_opening_details =
        record.job_opening_details && record.job_opening_details.length > 0
          ? record.job_opening_details[0]
          : undefined;

      if (!openingId && !interviewRecord) {
        console.log("failed to get openingId");
        return;
      }

      const params = {
        date_of_interview: values.date_of_interview,
        duration: values.duration,
        interview_type: values.interview_type,
        time_of_interview: values.time_of_interview,
        submission_id: job_opening_details
          ? job_opening_details._id
          : undefined,
        comment: values.comment,
        opening_id: interviewRecord ? interviewRecord.opening_id : openingId,
        bdm_id:
          job_opening_details && job_opening_details.bdm_id
            ? job_opening_details.bdm_id
            : "",
        candidate_id: candidateId,
        company_id: job_opening_details ? job_opening_details.company_id : "",
        message: "Interview has been scheduled on ",
        status: status,
        recruiter_id: interviewRecord
          ? interviewRecord.recruiter_id
          : recruiterId,
        freelancer_recruiter_id:
          job_opening_details && job_opening_details.freelancer_recruiter_id
            ? job_opening_details.freelancer_recruiter_id
            : "",
      };
      if (interviewRecord) {
        params["interview_id"] = interviewRecord._id;
      }

      setSuccess(false);
      setLoading(true);
      setError(false);
      setMsgSuccess("");
      setmsgError("");

      axios
        .post(
          interviewRecord
            ? UPDATE_INTERVIEW_SCHEDULE
            : COMPANY_INTERVIEW_SCHEDULE,
          params,
          {
            headers: { Authorization: users.token },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            updateCandidateStatus(record, status);
            // onClose();
            setSuccess(true);
            setLoading(false);
            setError(false);
            setMsgSuccess(res.data.message);
            getCandidateDetail();
            setDefaultState();

            // setTimeout(() => {
            //   props.history.goBack();
            // }, 3000);
          }
        })
        .catch((error) => {
          let errorMessage = "";
          {
            Object.entries(error.response.data.errors).map(([key, value]) => {
              return (errorMessage += value + ", ");
            });
          }
          setSuccess(false);
          setLoading(false);
          setError(true);
          setmsgError(errorMessage);
          setDefaultState();
          if (error?.response?.status == 401) {
            logoutHandler(history);
          }
        });
    }
  };

  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setLoading(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };

  const emeployeedata = (employers) => {
    if (employers && employers.length <= 0) {
      return (
        <Row gutter={24} className="mb-5">
          {EmptyView()}
        </Row>
      );
    }

    return employers.map((employer, index) => {
      return (
        <Row gutter={24} key={index} className="mb-5">
          <Col span={24} className="mb-10">
            <Title level={5} className="mb-0">
              {employer.designation}
            </Title>
            <Text type="secondary">{employer.organization}</Text>
            <br />
            <Text type="secondary">
              {employer.work_since_from_month &&
                months.find(
                  (month) =>
                    month.value == employer.work_since_from_month ||
                    employer.work_since_from_month.includes(month.label)
                )?.label +
                  "/" +
                  employer.work_since_from_year}{" "}
              to{" "}
              {!employer.is_current_company
                ? employer.work_since_to_month &&
                  months.find(
                    (month) => month.value == employer.work_since_to_month
                  )?.label +
                    "/" +
                    employer.work_since_to_year
                : "Present"}
            </Text>
            <br />
            <Text type="secondary">
              Available to join in{" "}
              {
                noticePeriod.find(
                  (notice) => notice.value == employer.notice_period
                )?.label
              }
            </Text>
            <br />
            <Text type="secondary">{employer.description_job_profile}</Text>
          </Col>
        </Row>
      );
    });
  };

  const qualificationsdata = () => {
    const qualifications = userProfileDetails.candidate_qualifications;

    if (qualifications && qualifications.length <= 0) {
      return (
        <Row gutter={24} className="mb-5">
          {EmptyView()}
        </Row>
      );
    }
    return qualifications.map((qualification, index) => {
      // const course = Courses.find((item) => {
      //   return parseInt(item.value) === parseInt(qualification.course);
      // });
      return (
        <Row key={index} gutter={24} className="mb-5">
          <Col span={24} className="mb-10">
            <Title level={5} className="mb-0">
              {/* {course ? course.label : ""} */}
              {qualification.course}
            </Title>

            <Text type="secondary">{qualification.university}</Text>
            <br />
            <Text type="secondary">
              {qualification.passing_year} ({qualification.course_type})
            </Text>
            <br />
          </Col>
        </Row>
      );
    });
  };

  const candidateStatusChange = (record, status) => {
    // if (users.role === "recruiter" || users.role === "freelancerecruiter") {
    //   return;
    // }

    console.log("hello tested..", status);
    if (status === "I") {
      showDetails();
    } else {
      console.log("hello tested..");
      updateCandidateStatus(record, status);
    }
  };

  function onDateChange(value, dateString) {
    setDateValue(dateString);
  }
  function onTimeChange(time, timeString) {
    setTimeValue(timeString);
  }

  useEffect(() => {
    if (editVisibleDetails) setDetails();
  }, [editVisibleDetails]);

  const setDetails = () => {
    const dateFormat = "YYYY/MM/DD";
    if (userProfileDetails) {
      if (formDetails.current) {
        // formDetails.current.setFieldsValue({
        //   interview_type: "", //userProfileDetails.interview_type,
        //   duration: "", // userProfileDetails.duration,
        // });

        if (!dateValue) {
          setDateValue(
            userProfileDetails.date_of_interview
              ? userProfileDetails.date_of_interview
              : moment(new Date(), dateFormat)
          );
          setTimeValue(
            userProfileDetails.time_of_interview
              ? userProfileDetails.time_of_interview
              : "0:00 AM"
          );
        }
      }
    } else {
      if (formDetails.current) {
        formDetails.current.setFieldsValue({
          interview_type: "",
          duration: "",
        });
      }
    }
  };

  const editSubmitDetails = (values) => {
    const dateFormat = "YYYY-MM-DD";
    values.date_of_interview = moment(dateValue).format(dateFormat);
    values.time_of_interview = timeValue;
    console.log(values, "beta formal hai");

    updateInterviewStatus(userProfileDetails, submissionStatus, values);
  };

  const showDetails = () => {
    formDetails.current?.resetFields();
    setEditVisibleDetails(true);
  };

  const onClose = () => {
    setEditVisibleDetails(false);
  };

  const EditModalDetails = () => {
    const dateFormat = "YYYY/MM/DD";
    const timeFormat = "h:mm A";
    return (
      <Modal
        title="Interview Slots"
        width={620}
        onClose={onClose}
        onCancel={onClose}
        visible={editVisibleDetails}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              form="editDetails"
              // htmlType="submit"
              key="submit"
              type="primary"
              onClick={() => {
                formDetails.current.submit();
                onClose();
              }}
            >
              Submit
            </Button>
          </div>
        }
        // footer={
        //   <div
        //     style={{
        //       textAlign: "right",
        //     }}
        //   >
        //     {/* <Modal
        //       title="Interview Slots"
        //       width={620}
        //       onClose={onClose}
        //       onCancel={onClose}
        //       visible={editVisibleDetails}
        //       bodyStyle={{ paddingBottom: 80 }}
        //       footer={
        //         <div
        //           style={{
        //             textAlign: "right",
        //           }}
        //         >
        //           <Button onClick={onClose} style={{ marginRight: 8 }}>
        //             Cancel
        //           </Button>
        //           <Button
        //             form="editDetails"
        //             // htmlType="submit"
        //             key="submit"
        //             type="primary"
        //             onClick={() => {
        //               formDetails.current.submit();
        //               onClose();
        //             }}
        //           >
        //             Submit
        //           </Button>
        //         </div>
        //       }
        //     >
        //       <Form
        //         ref={formDetails}
        //         id="editDetails"
        //         layout="vertical"
        //         onFinish={editSubmitDetails}
        //       >
        //         <Card>
        //           <Row gutter={24}>
        //             <Col span={16}>
        //               <FormItem
        //                 label="Interview Type"
        //                 name={"interview_type"}
        //                 rules={[
        //                   {
        //                     required: true,
        //                     message: "Please Select Interview Type.",
        //                   },
        //                 ]}
        //               >
        //                 <Select>
        //                   <Option value="fa">Face to Face</Option>
        //                   <Option value="pi">Phone Interview</Option>
        //                   <Option value="pa">Panel Interview</Option>
        //                   <Option value="sv">Skype/Video Interview</Option>
        //                 </Select>
        //               </FormItem>
        //             </Col>
        //           </Row>
        //           <Row gutter={24}>
        //             <Col span={12}>
        //               <FormItem
        //                 label="Date"
        //                 name={"date_of_interview"}
        //                 // rules={[
        //                 //   {
        //                 //     required: true,
        //                 //     message: "Please Select Interview Date.",
        //                 //   },
        //                 // ]}
        //               >
        //                 <Space direction="vertical">
        //                   <DatePicker
        //                     onChange={onDateChange}
        //                     value={moment(dateValue, dateFormat)}
        //                     // format={dateFormat}
        //                   />
        //                 </Space>
        //               </FormItem>
        //             </Col>
        //             <Col span={12}>
        //               <FormItem
        //                 label="Time"
        //                 name={"time_of_interview"}
        //                 // rules={[
        //                 //   {
        //                 //     required: true,
        //                 //     message: "Please Select Interview Time.",
        //                 //   },
        //                 // ]}
        //               >
        //                 <Space direction="vertical">
        //                   <TimePicker
        //                     value={moment(timeValue, timeFormat)}
        //                     use12Hours
        //                     format="h:mm a"
        //                     onChange={onTimeChange}
        //                   />
        //                 </Space>
        //               </FormItem>
        //             </Col>
        //           </Row>
        //           <Row gutter={16}>
        //             <Col span={24}>
        //               <FormItem
        //                 label="Duration"
        //                 name={"duration"}
        //                 rules={[
        //                   {
        //                     required: true,
        //                     message: "Please Select Duration.",
        //                   },
        //                 ]}
        //               >
        //                 <Radio.Group>
        //                   <Radio value={"15"}>15 minutes</Radio>
        //                   <Radio value={"30"}>30 minutes</Radio>
        //                   <Radio value={"45"}>45 minutes</Radio>
        //                   <Radio value={"60"}>1 hour</Radio>
        //                 </Radio.Group>
        //               </FormItem>
        //             </Col>
        //             <Col span={24}>
        //               <FormItem
        //                 label="Comment"
        //                 name={"comment"}
        //                 // rules={[
        //                 //   {
        //                 //     required: true,
        //                 //     message: "Notes is required.",
        //                 //   },
        //                 // ]}
        //               >
        //                 <Input.TextArea rows={4} placeholder="Comment" />
        //               </FormItem>
        //             </Col>
        //           </Row>
        //         </Card>
        //       </Form>
        //     </Modal> */}
        //   </div>
        // }
      >
        <Form
          ref={formDetails}
          id="editDetails"
          layout="vertical"
          onFinish={editSubmitDetails}
        >
          <Card>
            <Row gutter={24}>
              <Col span={16}>
                <FormItem
                  label="Interview Type"
                  name={"interview_type"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Interview Type.",
                    },
                  ]}
                >
                  <Select>
                    <Option value="fa">Face to Face</Option>
                    <Option value="pi">Phone Interview</Option>
                    <Option value="pa">Panel Interview</Option>
                    <Option value="sv">Skype/Video Interview</Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem
                  label="Date"
                  name={"date_of_interview"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Interview Date.",
                  //   },
                  // ]}
                >
                  <Space direction="vertical">
                    <DatePicker
                      onChange={onDateChange}
                      value={moment(dateValue, dateFormat)}
                      // format={dateFormat}
                    />
                  </Space>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label="Time"
                  name={"time_of_interview"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Interview Time.",
                  //   },
                  // ]}
                >
                  <Space direction="vertical">
                    <TimePicker
                      value={moment(timeValue, timeFormat)}
                      use12Hours
                      format="h:mm a"
                      onChange={onTimeChange}
                    />
                  </Space>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label="Duration"
                  name={"duration"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Duration.",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={"15"}>15 minutes</Radio>
                    <Radio value={"30"}>30 minutes</Radio>
                    <Radio value={"45"}>45 minutes</Radio>
                    <Radio value={"60"}>1 hour</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="Comment"
                  name={"comment"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Notes is required.",
                  //   },
                  // ]}
                >
                  <Input.TextArea rows={4} placeholder="Comment" />
                </FormItem>
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    );
  };

  const UsersView = () => {
    const item = location.state ? location.state.item : undefined;
    const submission_status =
      userProfileDetails.candidate_submission?.submission_status;
    const opening_id = userProfileDetails.candidate_submission?.opening_id;
    const job_opening_details = userProfileDetails.job_opening_details;
    const statusObject = CandidateSubmissionStatus.find((status) => {
      return submission_status
        ? submission_status.toUpperCase() === status.value.toUpperCase()
        : false;
    });

    const openingId = opening_id;

    return (
      <Row gutter={24} className="mb-5 mt-0">
        <Col span={users.role !== "admin" ? 12 : 24}>
          <Card style={{ backgroundColor: "#33549f" }} bordered={false}>
            <Space align="start" wrap={"Auto wrap line"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  style={{
                    backgroundColor: "#ed7206",
                    marginRight: "8px", // Add some spacing between Avatar and Button
                  }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  icon={
                    userProfileDetails.profile_image ? (
                      <Image
                        width={150}
                        preview={false}
                        src={
                          IMAGE_CANDIDATE_URL + userProfileDetails.profile_image
                        }
                      />
                    ) : (
                      <Title className="mb-0 text-white">
                        {userProfileDetails.first_name &&
                        userProfileDetails.first_name !== ""
                          ? userProfileDetails.first_name
                              .charAt(0)
                              .toUpperCase()
                          : ""}
                      </Title>
                    )
                  }
                >
                  <Title className="mb-0 text-white"></Title>
                </Avatar>

                {(users.role == "bdm" ||
                  users.role == "recruiter" ||
                  users.role == "freelancerecruiter" ||
                  users.role == "admin") && (
                  <NavLink
                    to={{
                      pathname: `/${props?.role || users.role}/edit-candidate`,
                      state: { item: userProfile?.data[0] },
                    }}
                  >
                    <Button type="primary" icon={<EditOutlined />} />
                  </NavLink>
                )}
              </div>
              <Row gutter={24} className="mb-5">
                <Col span={24}>
                  <div className="pl-5">
                    <Title level={4} className="mb-0 text-white text-uppercase">
                      {userProfileDetails.first_name +
                        " " +
                        userProfileDetails.last_name}
                    </Title>

                    <Text style={{ color: "#ffffffb3" }} className="mb-5">
                      {position
                        ? position.designation + " at " + position.organization
                        : ""}
                    </Text>
                    <Row gutter={24} className="pl-2">
                      <Col span="12" className="p-1">
                        <RoomIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.current_location
                            ? userProfileDetails.current_location
                            : "-"}
                        </Text>
                      </Col>
                      <Col span="12" className="p-1">
                        <CallIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.mobile
                            ? userProfileDetails.mobile
                            : "-"}
                        </Text>
                      </Col>
                      <Col span="12" className="p-1">
                        <BusinessCenterIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.total_work_exp_year +
                            " Year(s) " +
                            userProfileDetails.total_work_exp_month +
                            " Month(s)"}
                        </Text>
                      </Col>
                      <Col span="12" className="p-1">
                        <EmailIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.email
                            ? userProfileDetails.email
                            : "-"}
                        </Text>
                      </Col>
                    </Row>
                    <Row gutter={24} className="pl-2">
                      <Col span="12" className="p-1">
                        <AccountBalanceWalletIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.current_ctc
                            ? parseInt(userProfileDetails.current_ctc) > 1000
                              ? "$ " +
                                parseInt(userProfileDetails.current_ctc) /
                                  1000 +
                                " K"
                              : userProfileDetails.current_ctc
                            : "$ 0.0"}
                        </Text>
                      </Col>
                    </Row>
                    <Col span={"12"} className="p-1">
                      {userProfileDetails?.tags?.map((tag) => (
                        <Tag key={tag} color="blue">
                          {tag}
                        </Tag>
                      ))}
                    </Col>
                  </div>
                </Col>
              </Row>
            </Space>
          </Card>
        </Col>
        {users.role == "admin" ||
        users.role == "recruiter" ||
        users.role == "freelancerecruiter" ? (
          <Col span={12}>
            <Card>
              <Row gutter={24} className="mb-5">
                {/* {getModal()} */}

                <CandidateSubmissionTable
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  jobData={jobData}
                  pagination={pagination}
                  onFetchData={handlePaginationChange}
                  onSubmit={submitCandidateToJob}
                  setSearch={setSearch}
                />
              </Row>
              <Row gutter={24} className="mb-5">
                <Col span={12}>
                  <Title level={5} className="mb-0">
                    Opening Title
                  </Title>
                </Col>
                <Col span={12}>
                  <Text>
                    {/* {job_opening_details?.length > 0
                    ? job_opening_details[0].opening_title
                    : "-"} */}
                    {job_opening_details?.opening_title || "-"}
                  </Text>
                </Col>
              </Row>
              <Row gutter={24} className="mb-5">
                <Col span={12}>
                  <Title level={5} className="mb-0">
                    Short Description
                  </Title>
                </Col>
                <Col span={12}>
                  <Text>{job_opening_details?.short_description}</Text>
                </Col>
              </Row>
              <Row gutter={24} className="mb-5">
                <Col span={12}>
                  <Title level={5} className="mb-0">
                    Salary Range ({job_opening_details?.salary_type})
                  </Title>
                </Col>
                <Col span={12}>
                  <Text>
                    ${job_opening_details?.salary_range_from} -
                    {job_opening_details?.salary_range_to}
                  </Text>
                </Col>
              </Row>
              <Row gutter={24} className="mb-5">
                <Col span={12}>
                  <Title level={5} className="mb-0">
                    Submission Status
                  </Title>
                </Col>
                <Col span={12}>
                  {/* {statusObject
                    ? statusObject.label
                    : submissionStatus.charAt(0, 1).toUpperCase() +
                    submissionStatus.slice(1)} */}
                  <Select
                    placeholder="Select Status Name"
                    disabled={
                      users.role !== "bdm" &&
                      users.role !== "recruiter" &&
                      users.role !== "bdm" &&
                      users.role !== "admin"
                    }
                    value={
                      statusObject
                        ? statusObject?.label
                        : submissionStatus.charAt(0, 1).toUpperCase() +
                          submissionStatus.slice(1)
                    }
                    onChange={(status) => {
                      setSubmissionStatus(status);
                      candidateStatusChange(userProfileDetails, status);
                    }}
                    style={{ width: "100%" }}
                  >
                    <Option value={""}>{"Select"}</Option>
                    {CandidateSubmissionStatus != undefined &&
                      CandidateSubmissionStatus.map((status, index) => (
                        <Option key={index.toString()} value={status.value}>
                          {status?.label}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>

              <Row gutter={24} className="mb-5">
                <Col span={12}>
                  <Title level={5} className="mb-0">
                    Notes
                  </Title>
                </Col>
                <Col span={12}>
                  <Input.TextArea
                    disabled={
                      users.role !== "bdm" &&
                      users.role !== "recruiter" &&
                      users.role !== "admin"
                    }
                    rows={4}
                    placeholder="Comment"
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ) : null}
      </Row>
    );
  };

  const CompanyView = () => {
    const job_opening_details = userProfileDetails.job_opening_details;

    return (
      <Row gutter={24} className="mb-5 mt-0">
        <Col span={12}>
          <Card style={{ backgroundColor: "#33549f" }} bordered={false}>
            <Space align="start" wrap={"Auto wrap line"}>
              <div>
                <Avatar
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#ed7206",
                  }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  icon={
                    userProfileDetails.profile_image ? (
                      <Image
                        width={150}
                        preview={false}
                        src={
                          IMAGE_CANDIDATE_URL + userProfileDetails.profile_image
                        }
                      />
                    ) : (
                      <Title className="mb-0 text-white">
                        {userProfileDetails.first_name
                          ? userProfileDetails.first_name
                              .charAt(0)
                              .toUpperCase()
                          : ""}
                      </Title>
                    )
                  }
                >
                  <Title className="mb-0 text-white">J</Title>
                </Avatar>
              </div>
              <Row gutter={24} className="mb-5">
                <Col span={24}>
                  <div className="pl-5">
                    <Title level={4} className="mb-0 text-white text-uppercase">
                      {userProfileDetails.first_name +
                        " " +
                        userProfileDetails.last_name}
                    </Title>
                    <Text style={{ color: "#ffffffb3" }} className="mb-5">
                      {position
                        ? position.designation + " at " + position.organization
                        : "-"}
                    </Text>
                    <Row gutter={24} className="pl-2">
                      <Col span="12" className="p-1">
                        <RoomIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.current_location
                            ? userProfileDetails.current_location
                            : "-"}
                        </Text>
                      </Col>
                      <Col span="12" className="p-1">
                        <CallIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.mobile
                            ? userProfileDetails.mobile
                            : "-"}
                        </Text>
                      </Col>
                      <Col span="12" className="p-1">
                        <BusinessCenterIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.total_work_exp_year +
                            " Year(s) " +
                            userProfileDetails.total_work_exp_month +
                            " Month(s)"}
                        </Text>
                      </Col>
                      <Col span="12" className="p-1">
                        <EmailIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.email
                            ? userProfileDetails.email
                            : "-"}
                        </Text>
                      </Col>
                    </Row>
                    <Row gutter={24} className="pl-2">
                      <Col span="12" className="p-1">
                        <AccountBalanceWalletIcon
                          style={{ color: "#ffffffb3", marginRight: "3px" }}
                        />
                        <Text className="text-white">
                          {userProfileDetails.current_ctc
                            ? parseInt(userProfileDetails.current_ctc) > 1000
                              ? "$ " +
                                parseInt(userProfileDetails.current_ctc) /
                                  1000 +
                                " K"
                              : userProfileDetails.current_ctc
                            : "$ 0.0"}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Space>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Row gutter={24} className="mb-5">
              <Col span={12}>
                <Title level={5} className="mb-0">
                  Opening Title
                </Title>
              </Col>
              <Col span={12}>
                <Text>{job_opening_details?.opening_title}</Text>
              </Col>
            </Row>

            <Row gutter={24} className="mb-5">
              <Col span={12}>
                <Title level={5} className="mb-0">
                  Short Description
                </Title>
              </Col>
              <Col span={12}>
                <Text>{job_opening_details?.short_description}</Text>
              </Col>
            </Row>
            <Row gutter={24} className="mb-5">
              <Col span={12}>
                <Title level={5} className="mb-0">
                  Salary Range (Annualy)
                </Title>
              </Col>
              <Col span={12}>
                <Text>
                  $
                  {location.state
                    ? location.state.item.opening_details
                      ? location.state.item.opening_details
                          .job_opening_details[0].salary_range
                      : "0.00"
                    : "0.00"}
                </Text>
              </Col>
            </Row>

            <Row gutter={24} className="mb-5">
              <Col span={12}>
                <Title level={5} className="mb-0">
                  Submission Status
                </Title>
              </Col>
              <Col span={12}>
                <Select
                  placeholder="Select Status Name"
                  value={
                    location.state
                      ? location.state.item?.opening_details?.submission_status
                      : userProfileDetails.job_opening_details &&
                        userProfileDetails.job_opening_details.length > 0
                      ? userProfileDetails.job_opening_details
                          .submission_status === "active"
                        ? "Active"
                        : userProfileDetails.job_opening_details[0]
                            .submission_status
                      : ""
                  }
                  onChange={(status) => {
                    setSubmissionStatus(status);
                    candidateStatusChange(userProfileDetails, status);
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value={""}>{"Select"}</Option>
                  {CandidateSubmissionStatus != undefined &&
                    CandidateSubmissionStatus.map((status, index) => (
                      <Option key={index.toString()} value={status.value}>
                        {status?.label}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>

            <Row gutter={24} className="mb-5">
              <Col span={12}>
                <Title level={5} className="mb-0">
                  Notes
                </Title>
              </Col>
              <Col span={12}>
                <Input.TextArea
                  rows={4}
                  placeholder="Comment"
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  };
  const EmptyView = () => {
    return (
      <Col span={24}>
        <Empty
          imageStyle={{
            height: 60,
          }}
          description={<span> Data Not Found </span>}
        ></Empty>
      </Col>
    );
  };
  const EmptyViewResume = () => {
    return (
      <Col span={24}>
        <Empty
          imageStyle={{
            height: 60,
          }}
          description={<span> No Attachments </span>}
        ></Empty>
      </Col>
    );
  };

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>Data Not Found</p>
    </div>
  );

  const userProfileDetails =
    userProfile.data !== undefined ? userProfile.data[0] : null;

  const category = categoryList.find((item) => {
    return item.code === userProfileDetails
      ? userProfileDetails.job_category
      : "";
  });

  const position =
    userProfileDetails &&
    userProfileDetails.employees.find((employer) => {
      return employer.is_current_company === true;
    });

  const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

  return userProfileDetails ? (
    <div>
      <Spin indicator={antIcon} spinning={loading}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Overview" key="1">
            <Row gutter={24} className="mr-0">
              <NavLink
                className="pull-right w-100 text-right"
                to={
                  {
                    // pathname: "/company/job-openings",
                  }
                }
              >
                <Button
                  onClick={() => {
                    props.history.goBack();
                  }}
                  type="Secondary"
                >
                  Back
                </Button>
              </NavLink>
            </Row>
            {users.role === "company" ? CompanyView() : UsersView()}

            <Row gutter={24} className="mb-5 mt-0">
              <Col span={12}>
                <Card title="Personal Details" className="mb-6">
                  <Row gutter={24} className="mb-5">
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Date of Birth
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.date_of_birth
                          ? moment(userProfileDetails.date_of_birth).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Permanent Address
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.permanent_address
                          ? userProfileDetails.permanent_address
                          : "-"}
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={24} className="mb-5">
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Gender
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.gender
                          ? userProfileDetails.gender
                          : "-"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Area Pin Code
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.area_pin_code
                          ? userProfileDetails.area_pin_code
                          : "-"}
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={24} className="mb-5">
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Hometown
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.home_town
                          ? userProfileDetails.home_town
                          : "-"}
                      </Text>
                    </Col>
                  </Row>
                </Card>
                <Card title="Career Profile">
                  <Row gutter={24} className="mb-5">
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Job Category
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails &&
                        userProfileDetails.job_category &&
                        userProfileDetails.job_category.length > 0
                          ? userProfileDetails.job_category[0].name
                          : "-"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Role
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.role
                          ? userProfileDetails.role
                          : "-"}
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={24} className="mb-5">
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Desired Job Type
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.desired_job_type
                          ? userProfileDetails.desired_job_type
                          : "-"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Desired Employment Type
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.desired_employment_type
                          ? optionEmploymentType.find(
                              (item) =>
                                item.value ===
                                userProfileDetails.desired_employment_type
                            )?.label
                          : ""}
                        {/* {desiredEmploymentType
                      ? desiredEmploymentType.label
                      : "-"} */}
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={24} className="mb-5">
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        Desired Shift
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.desired_shift
                          ? userProfileDetails.desired_shift
                          : "-"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Title level={5} className="mb-0">
                        desired Location
                      </Title>
                      <Text type="secondary">
                        {userProfileDetails.desired_location
                          ? userProfileDetails.desired_location
                          : "-"}
                      </Text>
                    </Col>
                  </Row>
                </Card>
                <Card title="Education" className="mb-6 mt-6">
                  {userProfileDetails ? qualificationsdata() : null}
                </Card>

                <Card title="Social">
                  <Row gutter={[24, 16]} className="mb-5">
                    {userProfileDetails?.meta ? (
                      Object.keys(userProfileDetails?.meta).map(
                        (data, index) => (
                          <Col span={12} key={index}>
                            <Title level={5} className="mb-0">
                              {data}
                            </Title>
                            <Text type="secondary">
                              <a
                                href={
                                  userProfileDetails
                                    ? userProfileDetails?.meta[data]
                                    : "-"
                                }
                                target="_blank"
                              >
                                {userProfileDetails
                                  ? userProfileDetails?.meta[data]
                                  : "-"}
                              </a>
                            </Text>
                          </Col>
                        )
                      )
                    ) : (
                      <EmptyView />
                    )}
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Attach Resume" className="mb-6">
                  <Row gutter={24} className="mb-5">
                    {userProfileDetails.attachments ? (
                      <Col span={24}>
                        <Row>
                          <Col span={12}>{userProfileDetails.attachments}</Col>
                          <Col span={12} className="text-right">
                            <a
                              href={
                                CANDIDATE_CV_UPLOAD +
                                userProfileDetails.attachments
                              }
                              download
                              target="_blank"
                            >
                              <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                className="mr-5"
                              >
                                Download
                              </Button>
                            </a>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      EmptyViewResume()
                    )}
                  </Row>
                </Card>
                <Card title="Profile Summary" className="mb-6">
                  <Row gutter={24} className="mb-5">
                    {userProfileDetails.profile_summary ? (
                      <Col span={24}>
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="scroll"
                          style={{ maxHeight: "45vh", position: "relative" }}
                        >
                          <Text type="secondary">
                            {userProfileDetails.profile_summary}
                          </Text>
                        </PerfectScrollbar>
                      </Col>
                    ) : (
                      EmptyView()
                    )}
                  </Row>
                </Card>
                <Card title="Employment" className="mb-6">
                  {emeployeedata(userProfileDetails.employees)}
                </Card>
              </Col>
            </Row>

            {showError(success, msgSuccess, error, msgError)}
            {/* {<EditModalDetails />} */
            EditModalDetails()}
            {/* Submissions Tab */}
            {/* Notes Tab */}
          </TabPane>
          <TabPane tab="Notes" key="2">
            <Card title="Notes" className="mb-6">
              <Row gutter={24} className="mb-5">
                <Notes
                  handleCancel={() => setShowNotes(false)}
                  isVisible={showNotes}
                  handleNotes={handleNotes}
                  notes={NotesData}
                  getNotes={() => {getNotes(userProfile?.data[0])}}
                  jobOpenings={selectedRow}
                  user={users}
                  updateNoteState={setShowNotes}
                  handleUpdateNote={handleUpdateNotes}
                  handleDeleteNote={handleDeleteNotes}
                  notesField={"multipleNotes"}
                  isModal={false}
                />
              </Row>
            </Card>
          </TabPane>

          {(users.role == "recruiter" ||
            users.role == "freelancerecruiter" ||
            users.role == "admin") && (
            <TabPane tab="Submissions" key="3">
              <Card title="Submissions" className="mb-6">
                <Row gutter={24} className="mb-5">
                  {/* Insert content or component to manage submissions here */}
                  <Col span={12}>
                    <Button onClick={openSubmitToJobModal}>
                      Submit Candidate
                    </Button>
                  </Col>
                  <SubmissionHistory
                    data={userProfile?.data[0].candidate_submissions}
                    candidateStatusChange={candidateStatusChange}
                    setSubmissionStatus={setSubmissionStatus}
                    userProfileDetails={userProfileDetails}
                    setOpeningId={setOpeningId}
                    setBdmId={setBdmId}
                    setRecruiterId={setRecruiterId}
                  />
                </Row>
              </Card>
            </TabPane>
          )}

          <TabPane tab="Interviews" key="4">
            <InterviewTable
              setInterviewRecord={handleEditInterviewRecord}
              interviews={userProfile?.data[0].interviews}
              setEditVisibleDetails={setEditVisibleDetails}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  ) : (
    <div></div>
  );
}

export default CandidateProfileDetail;

const CandidateSubmissionTable = ({
  isModalVisible,
  setIsModalVisible,
  jobData,
  pagination,
  onFetchData,
  onSubmit,
  setSearch,
}) => {
  const users = useSelector(({ users }) => users);
  const timeoutRef = useRef(null);
  const columns = [
    // {
    //   title: "Job Id",
    //   dataIndex: "opening_id",
    //   key: "opening_id",

    // },
    {
      title: "Opening id",
      dataIndex: "opening_id",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => (
        <Typography.Text copyable>{record.opening_id}</Typography.Text>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "opening_title",
      key: "opening_title",
    },
    {
      title: "Number of Opening",
      dataIndex: "number_of_openings",
      key: "number_of_openings",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      width: 150, // Adjust the width as needed
      render: (text, record) => (
        <Button
          onClick={() => handleOnSubmit(record)}
          type="primary"
          disabled={record.isSubmitted} // Disable button if isSubmitted is true
        >
          Submit to Job
        </Button>
      ),
    },
  ];

  const getJobAssignmentDetails = async (id) => {
    try {
      const res = await axios.post(
        JOB_ASSIGNMENT_DETAILS,
        {
          recruiter_id: users.user._id,
          opening_id: id,
        },
        {
          headers: { Authorization: users.token },
        }
      );
      const bdmId = res.data.data[0].created_by;
      return bdmId;
    } catch (error) {
      if (error?.response?.status === 401) {
        logoutHandler(history);
      }
      throw error; // Throw the error for handling where the function is called
    }
  };

  // Function to handle the submission with confirmation
  const handleOnSubmit = async (record) => {
    const bdmId = await getJobAssignmentDetails(record.opening_id);
    Modal.confirm({
      title: "Are you sure you want to submit the candidate for this job?",
      content: `Job Title: ${record.opening_title}`, // Display job title for context
      okText: "Yes",
      cancelText: "No",
      onOk: () => onSubmit(record, bdmId), // Calls onSubmit with the record if confirmed
    });
  };

  return (
    <>
      <Modal
        title="Submit to Job"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={"50%"}
      >
        <Card title="Assigned Job List" bordered={false} className="px-0 py-0">
          <Row style={{ width: "100%" }} align="middle" justify="space-between">
            <Col>
              <Search
                placeholder="Search..."
                onChange={(e) => handleSearch(e, timeoutRef, setSearch)}
                style={{ width: 200 }}
              />
            </Col>
          </Row>

          <br />
          <Table
            dataSource={jobData}
            columns={columns}
            pagination={false}
            rowKey="id" // Assuming each job has a unique ID
          />
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={onFetchData}
          />
        </Card>
      </Modal>
    </>
  );
};

const SubmissionHistory = ({
  data,
  setSubmissionStatus,
  candidateStatusChange,
  userProfileDetails,
  setOpeningId,
  setRecruiterId,
  setBdmId,
}) => {
  const users = useSelector(({ users }) => users);
  const UpdateSubmissionStatus = useCallAPI(CANDIDATE_SUBMISSION_STATUS);

  const changeSubmissionStatus = async (submission_id, status) => {
    if (!submission_id || !status || status == "I") {
      console.log("something missing", status, submission_id);
      return;
    }
    await UpdateSubmissionStatus.post({
      submission_id: submission_id,
      status: status,
    });
  };
  const columns = [
    {
      title: "Opening id",
      dataIndex: "opening_id",
      show: true,
      key: () => {
        this.dataIndex;
      },
      render: (text, record) => (
        <Typography.Text copyable>{record.opening_id}</Typography.Text>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (text, record) => {
        return record.job_opening_details.opening_title;
      },
    },

    {
      title: "Submitted By",
      dataIndex: "recruiter_name",
      key: "recruiter_name",
      render: (text, record) => {
        return record.recruiter.last_name + " " + record.recruiter.first_name;
      },
    },
    {
      title: "status",
      dataIndex: "submission_status",
      key: "submission_status",
      render: (submission_status, record) => {
        return (
          <Select
            placeholder="Select Status Name"
            disabled={
              users.role !== "bdm" &&
              users.role !== "recruiter" &&
              users.role !== "bdm" &&
              users.role !== "admin"
            }
            value={submission_status}
            onChange={(status) => {
              setSubmissionStatus(status);
              candidateStatusChange(userProfileDetails, status);
              setOpeningId(record?.opening_id);
              setRecruiterId(record?.recruiter_id);
              setBdmId(record?.bdm_id);
              changeSubmissionStatus(record._id, status);
            }}
            style={{ width: "100%" }}
          >
            <Option value={""}>{"Select"}</Option>
            {CandidateSubmissionStatus != undefined &&
              CandidateSubmissionStatus.map((status, index) => (
                <Option key={index.toString()} value={status.value}>
                  {status?.label}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Submission Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => {
        return new Date(record.created_at).toLocaleDateString();
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="opening_id"
      pagination={false} // Disable pagination
    />
  );
};

const InterviewTable = ({
  interviews,
  setInterviewRecord,
  setEditVisibleDetails,
}) => {
  const columns = [
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      render: (title, record) => {
        return record?.job_opening_details?.opening_title;
      },
    },
    {
      title: "Recruiter ",
      dataIndex: "recruiter",
      key: "recruiter",
      render: (title, record) => {
        return record?.recruiter?.first_name;
      },
    },

    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Date of Interview",
      dataIndex: "date_of_interview",
      key: "date_of_interview",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Time of Interview",
      dataIndex: "time_of_interview",
      key: "time_of_interview",
    },
    {
      title: "Duration (minutes)",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Interview Type",
      dataIndex: "interview_type",
      key: "interview_type",
      render: (interviewType) => {
        return optionsInterviewType.find((type) => type.value == interviewType)
          .label;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <span>
          <Space style={{ marginRight: "5px" }} size="middle">
            <Button
              onClick={() => {
                setInterviewRecord(record);
                setEditVisibleDetails(true);
              }}
              type="primary"
              icon={<EditOutlined />}
            />
          </Space>
        </span>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={interviews}
      rowKey="_id"
      pagination={{ pageSize: 5 }}
    />
  );
};
