import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Typography,
  Button,
  Space,
  Divider,
  Tag,
  message,
  Modal,
  Tooltip,
} from "antd";
import moment from "moment";
import axios from "axios";
import {
  CANDIDATE_APPLY_JOB,
  CANDIDATE_REMOVE_JOB,
  CANDIDATE_SAVE_JOB,
  JOB_DETAIL,
  JOB_ASSIGNMENT_DETAILS,
  JOB_DETAIL_PUBLIC,
} from "../../../ApiUrl";
import { useSelector } from "react-redux";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import {
  SaveOutlined,
  CheckOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import {
  experienceRanges,
  optionEmploymentType,
  optionsexperienceLevel,
  optionsInterviewType,
} from "../constant/constant";
import { showError } from "../utils/helpers";
import { Helmet } from "react-helmet";

const { Text, Title } = Typography;

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL

export default function SearchJobDetail(props) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setmsgError] = useState("");
  const [openingDetails, setOpeningDetails] = useState({});
  const [CandiadteDetail, setCandiadteDetail] = useState();
  const { openingId } = useParams();
  console.log(DOMAIN_URL,"domain url..")

  const users = useSelector(({ users }) => users);
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getOpeningDetail();
    setCandiadteDetail(location.state ? location.state.item : "");
  }, []);

  const getOpeningDetail = () => {
    axios
      .get(
        openingId
          ? JOB_DETAIL_PUBLIC + "/" + openingId
          : JOB_DETAIL + "/" + location.state.item._id,
        {
          headers: { Authorization: users.token },
        }
      )
      .then((res) => setOpeningDetails(res.data.data[0]))
      .catch(() => {});
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalOk = () => {
    setIsModalVisible(false);
    history.push("/auth/registration");
  };

  const handleModalCancel = () => setIsModalVisible(false);

  const onApplyJob = async (item) => {
    const tokenExist = localStorage.getItem("token");
    if (!CandiadteDetail && !tokenExist) {
      localStorage.setItem(
        "redirectAfterSignup",
        `/openings/${openingDetails.opening_id}`
      );
      setIsModalVisible(true);
      return;
    }

    if (!item || !item.job_apply) {
      const res = await axios.post(
        JOB_ASSIGNMENT_DETAILS,
        { opening_id: openingDetails?.opening_id },
        { headers: { Authorization: users.token } }
      );

      let assignedRecruiters = new Set();
      res.data.data.forEach((element) => {
        if (!element.assigned_bdm.length) {
          assignedRecruiters.add(...element.assigned_recruiter);
        }
      });

      const params = {
        candidate_id: users.user._id,
        job_opening_id: openingDetails?.opening_id,
        recruiter_id: Array.from(assignedRecruiters),
        profile_submit: "1",
      };

      axios
        .post(CANDIDATE_APPLY_JOB, params, {
          headers: { Authorization: users.token },
        })
        .then((res) => {
          if (!res.data.error) {
            setSuccess(true);
            setError(false);
            setMsgSuccess(res.data.message);
            // setTimeout(() => props.history.goBack(), 3000);
            setDefaultState();
          }
        })
        .catch((error) => handleError(error));
    }
  };

  const onSaveJob = (item) => {
    const endpoint = item.job_save
      ? CANDIDATE_REMOVE_JOB + "/" + item.job_save._id
      : CANDIDATE_SAVE_JOB;
    const method = item.job_save ? "delete" : "post";
    const params = item.job_save
      ? null
      : { candidate_id: users.user._id, job_opening_id: item.opening_id };

    axios[method](endpoint, params, { headers: { Authorization: users.token } })
      .then((res) => !res.data.error && props.history.goBack())
      .catch((error) => handleError(error));
  };

  const handleError = (error) => {
    if (error.response) {
      const errorMsg = Object.values(error.response.data.errors).join(", ");
      setError(true);
      setmsgError(errorMsg);
      setDefaultState();
    }
  };

  const setDefaultState = () => {
    setTimeout(() => {
      setSuccess(false);
      setError(false);
      setMsgSuccess("");
      setmsgError("");
    }, 3000);
  };

  const createMarkup = () => ({ __html: openingDetails?.job_description });

  const daysAgoText = () => {
    const given = moment(openingDetails?.created_at, "YYYY-MM-DD");
    const current = moment();

    const minutes = current.diff(given, "minutes");
    const hours = current.diff(given, "hours");
    const days = current.diff(given, "days");
    const months = current.diff(given, "months");
    const years = current.diff(given, "years");

    if (minutes < 60) return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    if (hours < 24) return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    if (days < 30) return `${days} day${days !== 1 ? "s" : ""} ago`;
    if (months < 12) return `${months} month${months !== 1 ? "s" : ""} ago`;
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  };

  return (
    <div>
      <Helmet>
        <title>{openingDetails?.opening_title || "Job Details"} | Bix3</title>
        <meta
          name="description"
          content={
            openingDetails?.job_description
              ? openingDetails.job_description.slice(0, 150)
              : "Explore job opportunities on our platform."
          }
        />
        <meta
          name="keywords"
          content={
            openingDetails?.required_skills?.[0]?.split(",").join(", ") ||
            "jobs, hiring, careers"
          }
        />
        <meta
          property="og:title"
          content={openingDetails?.opening_title || "Job Details"}
        />
        <meta
          property="og:description"
          content={
            openingDetails?.job_description
              ? openingDetails.job_description.slice(0, 150)
              : "Job details and description."
          }
        />
        <meta
          property="og:url"
          content={`${DOMAIN_URL}/openings/${openingDetails?.opening_id}`}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={
            openingDetails?.company_logo ||
            `${DOMAIN_URL}/media/logos/bluebix-logo.png`
          }
        />
        <meta
          name="twitter:title"
          content={openingDetails?.opening_title || "Job Details"}
        />
        <meta
          name="twitter:description"
          content={
            openingDetails?.job_description
              ? openingDetails.job_description.slice(0, 150)
              : "Explore job opportunities at Bix3."
          }
        />
        {/* <meta
          name="twitter:image"
          content={
            openingDetails?.company_logo ||
            "https://bix3.app/default-twitter-image.jpg"
          }
        /> */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "JobPosting",
            title: openingDetails?.opening_title || "Job Title",
            description: openingDetails?.job_description,
            datePosted: openingDetails?.created_at,
            employmentType:
              optionEmploymentType.find(
                (type) => type.value === openingDetails?.employment_type
              )?.label || "Full-time",
            hiringOrganization: {
              "@type": "Organization",
              name: "BlueBix",
              sameAs: DOMAIN_URL,
            },
            jobLocation: {
              "@type": "Place",
              address: {
                "@type": "PostalAddress",
                addressCountry: openingDetails?.country || "Country",
              },
            },
            baseSalary: {
              "@type": "MonetaryAmount",
              currency: "USD",
              value: {
                "@type": "QuantitativeValue",
                minValue: openingDetails?.salary_range_from || 0,
                maxValue: openingDetails?.salary_range_to || 0,
                unitText: "YEAR",
              },
            },
            jobBenefits:
              openingDetails?.benefits || "Health insurance, Remote options",
          })}
        </script>
      </Helmet>

      {showError(success, msgSuccess, error, msgError)}
      <Row gutter={[24, 24]} style={{ padding: "20px" }}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <Title level={3}>
                  {openingDetails?.opening_title || "Job Title"}
                </Title>
                <Text type="secondary">{daysAgoText()}</Text>
              </Col>
              <Col>
                <Space>
                  <Tooltip title="Save Job">
                    <Button
                      type={CandiadteDetail?.job_save ? "primary" : "default"}
                      icon={<SaveOutlined />}
                      onClick={() => onSaveJob(CandiadteDetail)}
                    >
                      {CandiadteDetail?.job_save ? "Saved" : "Save"}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Apply for Job">
                    <Button
                      type={CandiadteDetail?.job_apply ? "primary" : "default"}
                      icon={<CheckOutlined />}
                      onClick={() => onApplyJob(CandiadteDetail)}
                    >
                      {CandiadteDetail?.job_apply ? "Applied" : "Apply"}
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Text>
                  <EnvironmentOutlined /> {openingDetails?.country}
                </Text>
              </Col>
              <Col span={8}>
                <Text>
                  Experience: {openingDetails?.required_experience || "N/A"} Yrs
                </Text>
              </Col>
              <Col span={8}>
                <Text>
                  Salary:{" "}
                  {openingDetails?.salary_range_from &&
                  openingDetails?.salary_range_to
                    ? `$${openingDetails.salary_range_from} - $${openingDetails.salary_range_to}`
                    : "Not Disclosed"}
                </Text>
              </Col>
            </Row>

            <Divider />

            <Space wrap>
              <Tag color="blue">
                Interview Type:{" "}
                {optionsInterviewType.find(
                  (type) => type.value === openingDetails?.interview_type
                )?.label || "N/A"}
              </Tag>
              <Tag color="green">
                Employment Type:{" "}
                {optionEmploymentType.find(
                  (type) => type.value === openingDetails?.employment_type
                )?.label || "N/A"}
              </Tag>
              <Tag color="orange">
                Experience Level:{" "}
                {optionsexperienceLevel.find(
                  (type) => type.value === openingDetails?.experience_level
                )?.label || "N/A"}
              </Tag>
            </Space>

            <Divider />

            <Row>
              <Col span={24}>
                <div dangerouslySetInnerHTML={createMarkup()} />
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                <Title level={5}>Key Skills</Title>
                <Space wrap>
                  {openingDetails?.required_skills?.[0]
                    ?.split(",")
                    ?.map((item, index) => (
                      <Tag key={index}>{item.trim()}</Tag>
                    ))}
                </Space>
              </Col>
            </Row>

            <Divider />

            <NavLink to="/company/job-openings">
              <Button type="primary">See All Jobs</Button>
            </NavLink>
          </Card>
        </Col>

        <Modal
          title="Sign Up Required"
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button key="signup" type="primary" onClick={handleModalOk}>
              Sign Up
            </Button>,
          ]}
        >
          <p>
            To apply for this job, you need to sign up first. Please sign up to
            continue.
          </p>
        </Modal>
      </Row>
    </div>
  );
}
